import React, { useContext } from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";

import AppContext from "./store/app-context";

import MainHeader from "./components/Layout/Navigation/MainHeader/MainHeader";
import MainFooter from "./components/Layout/Navigation/MainFooter/MainFooter";
import Home from "./components/Layout/Home/Home";
import Products from "./components/Layout/Products/Products";
import Service from "./components/Layout/Service/Service";
import Team from "./components/Layout/Team/Team";
import Jobs from "./components/Layout/Jobs/Jobs";
import Contact from "./components/Layout/Contact/Contact";
import LegalNotice from "./components/Layout/LegalNotice/LegalNotice";
import PrivacyNotice from "./components/Layout/PrivacyNotice/PrivacyNotice";

import ErrorPage from "./error-page";

function App() {
    const appContext = useContext(AppContext);

    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <React.Fragment>
                    <MainHeader />
                    <Outlet />
                    <MainFooter />
                </React.Fragment>
            ),
            errorElement: (
                <React.Fragment>
                    <MainHeader />
                    <ErrorPage />
                    <MainFooter />
                </React.Fragment>
            ),
            children: [
                {
                    path: "/",
                    element: (
                        <Home
                            featuredProjects={appContext.featuredProjects}
                            newProjects={appContext.newProjects}
                            teamLeader={appContext.teamLeader}
                        />
                    ),
                },
                {
                    path: "/services",
                    element: (
                        <Service
                            referenceProjects={appContext.referenceProjects}
                        />
                    ),
                },
                {
                    path: "/products",
                    element: (
                        <Products
                            currentProjects={appContext.currentProjects}
                            pastProjects={appContext.pastProjects}
                        />
                    ),
                },
                // {
                //     path: "/team",
                //     element: <Team fullTeam={appContext.fullTeam} />,
                // },
                {
                    path: "/jobs",
                    element: (
                        <Jobs
                            devJobs={appContext.devJobs}
                            ctrlJobs={appContext.ctrlJobs}
                            prdctJobs={appContext.prdctJobs}
                        />
                    ),
                },
                {
                    path: "/contact",
                    element: <Contact />,
                },
                {
                    path: "/legalnotice",
                    element: <LegalNotice />,
                },
                {
                    path: "/privacynotice",
                    element: <PrivacyNotice />,
                },
            ],
        },
    ]);

    return <RouterProvider router={router} />;
}

export default App;
