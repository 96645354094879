import React, { useState } from "react";

import styles from "./FileUpload.module.css";

const FileUpload = (props) => {
    const [inputHovered, setInputHovered] = useState(false);

    const onInputHover = () => {
        if (!inputHovered) setInputHovered(true);
    };

    const containerStyles = inputHovered
        ? styles["container-hovered"]
        : styles["container"];

    return (
        <React.Fragment>
            <label
                htmlFor={props.htmlFor}
                className={containerStyles}
                onMouseEnter={onInputHover}
                onClick={props.handleBlur}
            >
                <h5 className={styles["label"]}>{props.label}</h5>
                {!props.file && (
                    <p className={styles["text"]}>
                        Klicken Sie um die Datei auszuwählen
                    </p>
                )}
                {props.file && <p className={styles["text"]}>{props.file.name}</p>}
            </label>

            <input
                className={styles["input"]}
                id={props.htmlFor}
                type="file"
                accept=".pdf, .docx"
                onChange={props.handleChange}
            />
        </React.Fragment>
    );
};

export default FileUpload;
