import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Foot.module.css";

import Button from "../Button/Button";

const Foot = (props) => {
    const [buttonHovered, setButtonHovered] = useState(false);

    const navigate = useNavigate();

    const goToLink = () => {
        let path = props.path;
        navigate(path);
    };

    const onHoverButton = () => {
        if (!buttonHovered) setButtonHovered(true);
    };

    const buttonStyles = buttonHovered ? styles["btn-hovered"] : styles["btn"];

    return (
        <div className={styles.background}>
            <div className={styles.foot}>
                <h2>{props.headline}</h2>
                <p>
                    {props.children}
                </p>
                <Button
                    type="button"
                    onClick={goToLink}
                    className={buttonStyles}
                    onMouseEnter={onHoverButton}
                >
                    {props.btnText}
                </Button>
            </div>
        </div>
    );
};

export default Foot;
