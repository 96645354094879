import React, { useState, useEffect } from "react";

import { CSSTransition } from "react-transition-group";

import styles from "./Job.module.css";
import Button from "../../UI/Button/Button";

const Job = (props) => {
    const nodeRef = React.useRef(null);
    const [jobOpen, setJobOpen] = useState(false);
    const [jobClicked, setJobClicked] = useState(false);
    const [buttonHovered, setButtonHovered] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onClickJob = () => {
        if (jobOpen) {
            setJobOpen(false);
        } else {
            setJobOpen(true);
        }
        if (!jobClicked) {
            setJobClicked(true);
        }
    };
    const onHoverButton = () => {
        if (!buttonHovered) setButtonHovered(true);
    };

    const onClickButton = () => {
        props.onOpenForm(props.title);
    };

    const arrowStyles = jobClicked
        ? jobOpen
            ? styles["arrow-down"]
            : styles["arrow-right"]
        : styles["arrow-initial"];
    const buttonStyles = buttonHovered ? styles["btn-hovered"] : styles["btn"];

    return (
        <React.Fragment>
            <div className={styles["container"]}>
                <div className={styles["head"]} onClick={onClickJob}>
                    <h3>
                        {props.title} {" (m/w/d) "} in {props.time}
                    </h3>
                    <i className={arrowStyles} />
                </div>

                <CSSTransition
                    nodeRef={nodeRef}
                    in={jobOpen}
                    timeout={750}
                    mountOnEnter
                    unmountOnExit
                    classNames={{
                        enter: "",
                        enterActive: styles["job__open"],
                        exit: "",
                        exitActive: styles["job__close"],
                    }}
                >
                    <div className={styles["job"]} ref={nodeRef}>
                        <div className={styles["description"]}>
                            <h4 className={styles["description__header"]}>
                                Beschreibung
                            </h4>
                            <p className={styles["description-text"]}>
                                {props.description}
                            </p>
                        </div>

                        <div className={styles["qualification"]}>
                            <h4 className={styles["qualification__header"]}>
                                Qualifikationen
                            </h4>
                            <div className={styles["qualification-lists"]}>
                                <ul className={styles["qualification-list"]}>
                                    <h4
                                        className={
                                            styles["qualification-list__header"]
                                        }
                                    >
                                        Vorrausgesetzt
                                    </h4>
                                    {props.qualificationNecessary.map(
                                        (quali, index) => (
                                            <li
                                                key={index}
                                                className={
                                                    styles[
                                                        "qualification-list__element"
                                                    ]
                                                }
                                            >
                                                {quali}
                                            </li>
                                        )
                                    )}
                                </ul>
                                <ul className={styles["qualification-list"]}>
                                    <h4
                                        className={
                                            styles["qualification-list__header"]
                                        }
                                    >
                                        Optional
                                    </h4>
                                    {props.qualificationOptional.map(
                                        (quali, index) => (
                                            <li
                                                key={index}
                                                className={
                                                    styles[
                                                        "qualification-list__element"
                                                    ]
                                                }
                                            >
                                                {quali}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                            <p className={styles["qualification-text"]}>
                                {props.qualificationText}
                            </p>
                        </div>

                        <Button
                            className={buttonStyles}
                            onMouseEnter={onHoverButton}
                            onClick={onClickButton}
                        >
                            Jetzt Bewerben
                        </Button>
                    </div>
                </CSSTransition>
            </div>
        </React.Fragment>
    );
};

export default Job;
