import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import styles from "./Project.module.css";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";

import { FaWaveSquare, FaPlug } from "react-icons/fa";
import { GiPowerButton, GiTargetDummy } from "react-icons/gi";
import { RiChargingPile2Line } from "react-icons/ri";
import { MdError, MdCable } from "react-icons/md";
import { AiFillEye, AiFillControl } from "react-icons/ai";
import { BiAnalyse } from "react-icons/bi";

const Project = (props) => {
    const listStyles =
        props.background === "dark"
            ? `${styles["features-list"]} ${styles["light"]}`
            : `${styles["features-list"]} ${styles["dark"]}`;

    const imageSwiperStyles =
        props.background === "dark"
            ? styles["image-swiper__light"]
            : styles["image-swiper__dark"];

    return (
        <div className={styles["container"]}>
            <div className={styles["features"]}>
                <div className={styles["features-block"]}>
                    <h4>{props.title}</h4>
                    <ul className={listStyles}>
                        {props.features.map((feature, index) => (
                            <li
                                key={index}
                                className={
                                    styles["features-list__element"]
                                }
                            >
                                {feature}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={styles["features-icons"]}>
                    {props.plugCheck && (
                        <GiPowerButton
                            size={25}
                            className={styles["features-icons__element"]}
                        />
                    )}

                    {props.pwm && (
                        <FaWaveSquare
                            size={25}
                            className={styles["features-icons__element"]}
                        />
                    )}

                    {props.phases && (
                        <RiChargingPile2Line
                            size={25}
                            className={styles["features-icons__element"]}
                        />
                    )}
                    {props.cables && (
                        <FaPlug
                            size={25}
                            className={styles["features-icons__element"]}
                        />
                    )}
                    {props.pp && (
                        <MdCable
                            size={25}
                            className={styles["features-icons__element"]}
                        />
                    )}
                    {props.cp && (
                        <AiFillControl
                            size={25}
                            className={styles["features-icons__element"]}
                        />
                    )}
                    {props.errors && (
                        <MdError
                            size={25}
                            className={styles["features-icons__element"]}
                        />
                    )}

                    {props.automation && (
                        <BiAnalyse
                            size={25}
                            className={styles["features-icons__element"]}
                        />
                    )}
                    {props.design && (
                        <AiFillEye
                            size={25}
                            className={styles["features-icons__element"]}
                        />
                    )}
                    {props.layman && (
                        <GiTargetDummy
                            size={25}
                            className={styles["features-icons__element"]}
                        />
                    )}
                </div>
            </div>
            {props.images.length === 1 && (
                <div className={imageSwiperStyles}>
                    <div className={styles.image}>
                        <img
                            src={require(`../../../img/products/${props.imgFolder}/${props.images[0]}`)}
                            alt={props.alt}
                        />
                    </div>
                </div>
            )}
            {props.images.length > 1 && (
                <Swiper
                    pagination={{
                        type: "fraction",
                    }}
                    navigation={true}
                    autoplay={{
                        delay: 5000,
                    }}
                    loop={true}
                    modules={[Pagination, Navigation, Autoplay]}
                    className={imageSwiperStyles}
                    allowTouchMove={false}
                    spaceBetween={50}
                >
                    {props.images.map((img, index) => (
                        <SwiperSlide key={index}>
                            <div className={styles.image}>
                                <img
                                    src={require(`../../../img/products/${props.imgFolder}/${img}`)}
                                    alt={props.alt}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </div>
    );
};

export default Project;
