import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";

import postContactForm from "../../../functions/postContactForm";
import { useInputText, useInputCheckbox } from "../../../hooks/use-input";

import styles from "./ContactForm.module.css";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";

const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => value.includes("@") && value.includes(".");
const isChecked = (value) => value;

const ContactForm = (props) => {
    const nodeRef = React.useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [didSubmit, setDidSubmit] = useState(false);
    const [showBackdrop, setShowBackrop] = useState(false);
    const [buttonHovered, setButtonHovered] = useState(false);

    const {
        value: enteredName,
        isValid: enteredNameIsValid,
        hasError: nameInputHasError,
        valueChangeHandler: nameChangeHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetName,
    } = useInputText(isNotEmpty);

    const {
        value: enteredMail,
        isValid: enteredMailIsValid,
        hasError: mailInputHasError,
        valueChangeHandler: mailChangeHandler,
        inputBlurHandler: mailBlurHandler,
        reset: resetMail,
    } = useInputText(isEmail);

    const {
        value: enteredMsg,
        isValid: enteredMsgIsValid,
        hasError: msgInputHasError,
        valueChangeHandler: msgChangeHandler,
        inputBlurHandler: msgBlurHandler,
        reset: resetMsg,
    } = useInputText(isNotEmpty);

    const {
        value: enteredCheck,
        isValid: enteredCheckIsValid,
        hasError: checkInputHasError,
        valueChangeHandler: checkChangeHandler,
        inputBlurHandler: checkBlurHandler,
        reset: resetCheck,
    } = useInputCheckbox(isChecked);

    const formIsValid =
        enteredNameIsValid &&
        enteredMailIsValid &&
        enteredMsgIsValid &&
        enteredCheckIsValid;

    const formSubmissionHandler = async (event) => {
        event.preventDefault();

        if (!formIsValid) {
            return;
        }

        resetName();
        resetMail();
        resetMsg();
        resetCheck();

        setIsSubmitting(true);
        setShowBackrop(true);
        const response = await postContactForm(
            enteredName,
            enteredMail,
            enteredMsg
        );
        setIsSubmitting(false);
        setDidSubmit(true);
    };

    const dismissMessage = () => {
        setDidSubmit(false);
        setShowBackrop(false);
    };

    const onHoverButton = () => {
        if (!buttonHovered) setButtonHovered(true);
    };

    const nameInputClasses = nameInputHasError
        ? `${styles["form-control"]} ${styles["invalid"]}`
        : styles["form-control"];

    const mailInputClasses = mailInputHasError
        ? `${styles["form-control"]} ${styles["invalid"]}`
        : styles["form-control"];

    const msgInputClasses = msgInputHasError
        ? `${styles["form-control"]} ${styles["invalid"]}`
        : styles["form-control"];

    const buttonStyles = buttonHovered ? styles["btn-hovered"] : styles["btn"];

    return (
        <React.Fragment>
            <CSSTransition
                nodeRef={nodeRef}
                in={showBackdrop}
                timeout={400}
                mountOnEnter
                unmountOnExit
                classNames={{
                    enter: "",
                    enterActive: styles["backdrop__open"],
                    exit: "",
                    exitActive: styles["backdrop__close"],
                }}
            >
                <div className={styles.backdrop} ref={nodeRef} />
            </CSSTransition>

            {isSubmitting && (
                <div>
                    <div className={styles["lds-ring"]}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}

            <CSSTransition
                nodeRef={nodeRef}
                in={didSubmit}
                timeout={600}
                mountOnEnter
                unmountOnExit
                classNames={{
                    enter: "",
                    enterActive: styles["card__open"],
                    exit: "",
                    exitActive: styles["card__close"],
                }}
            >
                <div className={styles.card} ref={nodeRef}>
                    <div>
                        <p>
                            Vielen Dank für Ihr Interesse. <br /> Wir setzen uns
                            zeitnah mit Ihnen in Verbindung
                        </p>
                    </div>
                    <Button
                        type="submit"
                        className={buttonStyles}
                        onMouseEnter={onHoverButton}
                        onClick={dismissMessage}
                    >
                        Schließen
                    </Button>
                </div>
            </CSSTransition>

            <form className={styles.form} onSubmit={formSubmissionHandler}>
                <div className={nameInputClasses}>
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        value={enteredName}
                        onChange={nameChangeHandler}
                        onBlur={nameBlurHandler}
                    />
                    {nameInputHasError && (
                        <p className={styles["error-text"]}>
                            Bitte einen Namen eingeben
                        </p>
                    )}
                </div>
                <div className={mailInputClasses}>
                    <label htmlFor="mail">E-Mail</label>
                    <input
                        type="text"
                        id="mail"
                        value={enteredMail}
                        onChange={mailChangeHandler}
                        onBlur={mailBlurHandler}
                    />
                    {mailInputHasError && (
                        <p className={styles["error-text"]}>
                            Bitte eine gültige Email Adresse eingeben
                        </p>
                    )}
                </div>
                <div className={msgInputClasses}>
                    <label htmlFor="message">Nachricht</label>
                    <textarea
                        id={styles.message}
                        value={enteredMsg}
                        onChange={msgChangeHandler}
                        onBlur={msgBlurHandler}
                    />
                    {msgInputHasError && (
                        <p className={styles["error-text"]}>
                            Die Nachricht darf nicht leer sein
                        </p>
                    )}
                </div>
                <div className={styles["form-control"]}>
                    <label htmlFor="check">Datenschutz</label>
                    <div className={styles.privacy}>
                        <input
                            type="checkbox"
                            id={styles.check}
                            checked={enteredCheck}
                            onChange={checkChangeHandler}
                            onBlur={checkBlurHandler}
                        />
                        <p>
                            Ich habe die{" "}
                            <Link to="/privacynotice">Datenschutzerklärung</Link>{" "}
                            gelesen und akzeptiere sie
                        </p>
                    </div>
                    {checkInputHasError && (
                        <p className={styles["error-text"]}>
                            Datenschutzerklärung nicht akzeptiert
                        </p>
                    )}
                </div>

                <Button
                    type="submit"
                    className={buttonStyles}
                    onMouseEnter={onHoverButton}
                    disabled={!formIsValid}
                    onClick={formSubmissionHandler}
                >
                    Absenden
                </Button>
            </form>
        </React.Fragment>
    );
};

export default ContactForm;
