import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import styles from "./Products.module.css";

import { FaWaveSquare, FaPlug } from "react-icons/fa"; //CC BY 4.0 License
import { GiPowerButton, GiTargetDummy } from "react-icons/gi"; //CC BY 3.0 License
import { RiChargingPile2Line } from "react-icons/ri"; //Apache License Version 2.0
import { MdError, MdCable } from "react-icons/md"; //Apache License Version 2.0
import { AiFillEye, AiFillControl } from "react-icons/ai"; //MIT licence
import { BiAnalyse } from "react-icons/bi"; //CC BY 4.0 License

import Head from "../../UI/Head/Head";
import Foot from "../../UI/Foot/Foot";
import ProjectForm from "../../Content/Project/ProjectForm";

const Products = (props) => {
    const [projectFilter, setProjectFilter] = useState({
        plugCheck: false,
        pwm: false,
        phases: false,
        cables: false,
        pp: false,
        cp: false,
        errors: false,
        automation: false,
        design: false,
        layman: false,
    });
    const [filteredProjects, setFilteredProjecs] = useState([]);
    const [filtered, setFiltered] = useState(false);

    useEffect(() => {
        if (
            Object.values(projectFilter).every((element) => element === false)
        ) {
            setFilteredProjecs(props.currentProjects);
        } else {
            const filterElements = [];
            const filter = [];
            for (const [key, value] of Object.entries(projectFilter)) {
                if (value === true) {
                    filter.push(key);
                }
            }
            props.currentProjects.forEach(function (element) {
                if (
                    filter.every(function (b) {
                        return element[b];
                    })
                ) {
                    filterElements.push(element);
                }
            });
            setFiltered(true);
            setFilteredProjecs(filterElements);
        }
    }, [projectFilter, props.currentProjects]);

    const changeProjectFilter = (event) => {
        var id = parseInt(event.currentTarget.id);

        switch (id) {
            case 1:
                setProjectFilter({
                    ...projectFilter,
                    plugCheck: projectFilter.plugCheck !== true,
                });
                break;

            case 2:
                setProjectFilter({
                    ...projectFilter,
                    pwm: projectFilter.pwm !== true,
                });
                break;
            case 3:
                setProjectFilter({
                    ...projectFilter,
                    phases: projectFilter.phases !== true,
                });
                break;
            case 4:
                setProjectFilter({
                    ...projectFilter,
                    pp: projectFilter.pp !== true,
                });
                break;
            case 5:
                setProjectFilter({
                    ...projectFilter,
                    cables: projectFilter.cables !== true,
                });
                break;
            case 6:
                setProjectFilter({
                    ...projectFilter,
                    errors: projectFilter.errors !== true,
                });
                break;
            case 7:
                setProjectFilter({
                    ...projectFilter,
                    automation: projectFilter.automation !== true,
                });
                break;
            case 8:
                setProjectFilter({
                    ...projectFilter,
                    design: projectFilter.design !== true,
                });
                break;
            case 9:
                setProjectFilter({
                    ...projectFilter,
                    layman: projectFilter.layman !== true,
                });
                break;
            case 10:
                setProjectFilter({
                    ...projectFilter,
                    cp: projectFilter.cp !== true,
                });
                break;

            default:
                break;
        }
    };

    const plugCheckIconClasses = projectFilter.plugCheck
        ? styles["products-specs__element__icon--clicked"]
        : styles["products-specs__element__icon"];

    const pwmIconClasses = projectFilter.pwm
        ? styles["products-specs__element__icon--clicked"]
        : styles["products-specs__element__icon"];

    const phasesIconClasses = projectFilter.phases
        ? styles["products-specs__element__icon--clicked"]
        : styles["products-specs__element__icon"];

    const cablesIconClasses = projectFilter.cables
        ? styles["products-specs__element__icon--clicked"]
        : styles["products-specs__element__icon"];

    const ppIconClasses = projectFilter.pp
        ? styles["products-specs__element__icon--clicked"]
        : styles["products-specs__element__icon"];

    const cpIconClasses = projectFilter.cp
        ? styles["products-specs__element__icon--clicked"]
        : styles["products-specs__element__icon"];

    const errorsIconClasses = projectFilter.errors
        ? styles["products-specs__element__icon--clicked"]
        : styles["products-specs__element__icon"];

    const automationIconClasses = projectFilter.automation
        ? styles["products-specs__element__icon--clicked"]
        : styles["products-specs__element__icon"];

    const designIconClasses = projectFilter.design
        ? styles["products-specs__element__icon--clicked"]
        : styles["products-specs__element__icon"];

    const laymanIconClasses = projectFilter.layman
        ? styles["products-specs__element__icon--clicked"]
        : styles["products-specs__element__icon"];

    return (
        <React.Fragment>
            <Helmet>
                <title>Produkte</title>
                <meta
                    name="description"
                    content="Prüfadapter für E-Mobility | Prüfgeräte für Ladesäulen - MZT GmbH"
                />
                <meta name="revisit-after" content="1 days" />
                <link rel="canonical" href="https://www.mzt-gmbh.de/products" />
            </Helmet>
            <Head>Unsere Produkte</Head>

            <div className={styles["background__light"]}>
                <div className={styles["currentProducts"]}>
                    <h2 className={styles["currentProducts__head"]}>
                        Prüftechnik für E-Mobility
                    </h2>
                    <p className={styles["currentProducts__text"]}>
                        Wir sind ein mittelständisches Unternehmen, das sich auf
                        die Herstellung von Prüfgeräten für Ladesäulen und
                        Elektrofahrzeuge spezialisiert hat. Unsere Produkte
                        werden in der Entwicklung und im Servicebereich
                        eingesetzt und ermöglichen es, das Funktionsverhalten
                        von Ladesäulen und Elektrofahrzeugen mit Anschlussbuchse
                        Typ 2 und Typ 1 zu überprüfen. <br />
                        <br /> Durch die Nutzung unserer Prüfgeräte können
                        Fehlerquellen beim Ladeprozess schnell lokalisiert und
                        somit Kosten und Zeit gespart werden. Unsere Geräte
                        zeichnen sich durch ihre einfache Bedienung und ihre
                        umfangreichen Diagnosemöglichkeiten aus. Wir legen
                        großen Wert auf Qualität und Sicherheit und sind stets
                        bemüht, unsere Produkte stetig zu verbessern und an den
                        Bedarf unserer Kunden anzupassen.
                    </p>
                </div>
            </div>

            <div className={styles["background__dark"]}>
                <div className={styles["products"]}>
                    <p className={styles["products__text"]}>
                        Unsere Produkte decken eine große Bandbreite an
                        verschiedenen Funktionen ab. Vom professionellen Einsatz
                        bis zur vollständig automatisierten Laienprüfung ist
                        alles abgedeckt. Wir wissen, dass eine Unterscheidung
                        zwischen den Prüfgeräten manchmal nicht klar ersichtlich
                        sein kann, deshalb filtern Sie einfach nach Ihren
                        Bedürfnissen, indem Sie die entsprechenden Symbole
                        markieren.
                    </p>
                    <div className={styles["products-specs"]}>
                        <div className={styles["products-specs__element"]}>
                            <GiPowerButton
                                id={1}
                                size={50}
                                className={plugCheckIconClasses}
                                onClick={changeProjectFilter}
                            />
                            <div
                                className={
                                    styles["products-specs__element__text"]
                                }
                            >
                                <h3>Plug & Check</h3>
                                <p>
                                    Simulation eines Elektroautos zur
                                    Durchführung von Fehlerdiagnosen an der
                                    Ladesäule
                                </p>
                            </div>
                        </div>
                        <div className={styles["products-specs__element"]}>
                            <FaWaveSquare
                                id={2}
                                size={50}
                                className={pwmIconClasses}
                                onClick={changeProjectFilter}
                            />
                            <div
                                className={
                                    styles["products-specs__element__text"]
                                }
                            >
                                <h3>PWM-Signal</h3>
                                <p>
                                    Darstellung der Fequenz, des Duty Cycles
                                    sowie der oberen und unteren Halbwelle
                                </p>
                            </div>
                        </div>
                        <div className={styles["products-specs__element"]}>
                            <RiChargingPile2Line
                                id={3}
                                size={50}
                                className={phasesIconClasses}
                                onClick={changeProjectFilter}
                            />
                            <div
                                className={
                                    styles["products-specs__element__text"]
                                }
                            >
                                <h3>Phasen & Drehfeld</h3>
                                <p>
                                    Diagnose der Drehfeldrichtung und der Phasen
                                </p>
                            </div>
                        </div>
                        <div className={styles["products-specs__element"]}>
                            <MdCable
                                id={4}
                                size={50}
                                className={ppIconClasses}
                                onClick={changeProjectFilter}
                            />
                            <div
                                className={
                                    styles["products-specs__element__text"]
                                }
                            >
                                <h3>Proximity Pilot</h3>
                                <p>
                                    Simulation der Kabelzustände 13A / 20A / 32A
                                    / 63A
                                </p>
                            </div>
                        </div>
                        <div className={styles["products-specs__element"]}>
                            <AiFillControl
                                id={10}
                                size={50}
                                className={cpIconClasses}
                                onClick={changeProjectFilter}
                            />
                            <div
                                className={
                                    styles["products-specs__element__text"]
                                }
                            >
                                <h3>Control Pilot</h3>
                                <p>
                                    Simulation der Fahrzeugzustände A / B / C /
                                    D
                                </p>
                            </div>
                        </div>
                        <div className={styles["products-specs__element"]}>
                            <MdError
                                id={6}
                                size={50}
                                className={errorsIconClasses}
                                onClick={changeProjectFilter}
                            />
                            <div
                                className={
                                    styles["products-specs__element__text"]
                                }
                            >
                                <h3>Fehlerzustände</h3>
                                <p>
                                    Simulation der Fehler CPPE, Diode und PE
                                    Trennung, nach IEC 61851
                                </p>
                            </div>
                        </div>
                        <div className={styles["products-specs__element"]}>
                            <FaPlug
                                id={5}
                                size={50}
                                className={cablesIconClasses}
                                onClick={changeProjectFilter}
                            />
                            <div
                                className={
                                    styles["products-specs__element__text"]
                                }
                            >
                                <h3>Schuko Steckdose</h3>
                                <p>Möglichkeit der Prüfung unter Last</p>
                            </div>
                        </div>

                        <div className={styles["products-specs__element"]}>
                            <BiAnalyse
                                id={7}
                                size={50}
                                className={automationIconClasses}
                                onClick={changeProjectFilter}
                            />
                            <div
                                className={
                                    styles["products-specs__element__text"]
                                }
                            >
                                <h3>Automation</h3>
                                <p>
                                    Automatisierte Simulation und Diagnose eines
                                    vollständigen Ladevorganges
                                </p>
                            </div>
                        </div>
                        <div className={styles["products-specs__element"]}>
                            <GiTargetDummy
                                id={9}
                                size={50}
                                className={laymanIconClasses}
                                onClick={changeProjectFilter}
                            />
                            <div
                                className={
                                    styles["products-specs__element__text"]
                                }
                            >
                                <h3>Laienanwender</h3>
                                <p>
                                    Die meisten unserer Geräte sind für den
                                    professionellen Einsatz konzipiert. Produkte
                                    mit diesem Merkmal können jedoch von
                                    elektrotechnischen Laien bedient werden
                                </p>
                            </div>
                        </div>
                        <div className={styles["products-specs__element"]}>
                            <AiFillEye
                                id={8}
                                size={50}
                                className={designIconClasses}
                                onClick={changeProjectFilter}
                            />
                            <div
                                className={
                                    styles["products-specs__element__text"]
                                }
                            >
                                <h3>Designlinie</h3>
                                <p>
                                    Unsere neue Designlinie ist ansprechend und
                                    hochwertig gestaltet, um ein einheitliches
                                    Produktdesign zu ermöglichen
                                </p>
                            </div>
                        </div>
                    </div>
                    <ProjectForm
                        projects={filteredProjects}
                        filtered={filtered}
                    />
                </div>
            </div>
            <div className={styles["background__light"]}>
                <div className={styles["pastProducts"]}>
                    <h2 className={styles["pastProducts__head"]}>
                        Produkte aus unser Firmengeschichte
                    </h2>
                    <p className={styles["pastProducts__text"]}>
                        Seit vielen Jahren sind wir ein verlässlicher Partner
                        für Lösungen in verschiedensten Branchen. Angefangen bei
                        der Zerspanung, über die Herstellung von Sportgeräten
                        bis hin zur E-Mobility - wir haben zahlreiche
                        Herausforderungen gemeistert und eigene Produkte auf den
                        Markt gebracht.
                        <br />
                        <br />
                        Unsere langjährige Firmengeschichte spiegelt sich in der
                        Qualität unserer Produkte wider. Wir sind stolz darauf,
                        dass unsere Produkte in der Industrie, im Sport, in der
                        Baubranche und im Bereich der E-Mobility eingesetzt
                        werden.
                        <br />
                        <br />
                        Angefangen haben wir mit der Zerspanung und der
                        Herstellung von Fräsern, Hochfrequenzspindeln und
                        Induktionsschrumpfgeräten. Doch wir haben uns
                        kontinuierlich weiterentwickelt und auch in dem Bereich
                        Vibrationstrainingsgeräte Produkte auf den Markt
                        gebracht. Seit einigen Jahren konzentrieren wir unsere
                        Entwicklungsressourcen auf den Bereich E-Mobility.
                    </p>
                    <br />
                    <ProjectForm projects={props.pastProjects} />
                </div>
            </div>
            <Foot
                headline="Interesse geweckt?"
                path="/contact"
                btnText="Kontakt"
            >
                Sie interessieren sich für unsere nächsten Produkte oder sind
                Interessiert an einer Auftragsentwicklung? <br />
                <br />
                Zögern Sie nicht, uns zu kontaktieren!
            </Foot>
        </React.Fragment>
    );
};

export default Products;
