import React, { useState } from "react";

import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import styles from "./MainHeader.module.css";

const MainHeader = () => {
    const nodeRef = React.useRef(null);
    const [showMobileNav, setShowMobileNav] = useState(false);
    const [navItemStartHovered, setNavItemStartHovered] = useState(false);
    const [navItemAboutusHovered, setNavItemAboutusHovered] = useState(false);
    const [navItemServiceHovered, setNavItemServiceHovered] = useState(false);
    const [navItemTeamHovered, setNavItemTeamHovered] = useState(false);
    const [navItemJobsHovered, setNavItemJobsHovered] = useState(false);
    const [navItemContactHovered, setNavItemContactHovered] = useState(false);

    const manageMobileNav = () => {
        if (showMobileNav) setShowMobileNav(false);
        else if (!showMobileNav) setShowMobileNav(true);
    };

    const onHoverStart = () => {
        if (!navItemStartHovered) setNavItemStartHovered(true);
    };
    const onHoverAboutus = () => {
        if (!navItemAboutusHovered) setNavItemAboutusHovered(true);
    };
    const onHoverService = () => {
        if (!navItemServiceHovered) setNavItemServiceHovered(true);
    };
    const onHoverTeam = () => {
        if (!navItemTeamHovered) setNavItemTeamHovered(true);
    };
    const onHoverJobs = () => {
        if (!navItemJobsHovered) setNavItemJobsHovered(true);
    };
    const onHoverContact = () => {
        if (!navItemContactHovered) setNavItemContactHovered(true);
    };

    const navItemStartStyles = navItemStartHovered
        ? styles["main-nav__item--hovered"]
        : styles["main-nav__item"];
    const navItemAboutusStyles = navItemAboutusHovered
        ? styles["main-nav__item--hovered"]
        : styles["main-nav__item"];
    const navItemServiceStyles = navItemServiceHovered
        ? styles["main-nav__item--hovered"]
        : styles["main-nav__item"];
    const navItemTeamStyles = navItemTeamHovered
        ? styles["main-nav__item--hovered"]
        : styles["main-nav__item"];
    const navItemJobsStyles = navItemJobsHovered
        ? styles["main-nav__item--hovered"]
        : styles["main-nav__item"];
    const navItemContactStyles = navItemContactHovered
        ? `${styles["main-nav__item--hovered"]} ${styles["main-nav__item--cta--hovered"]}`
        : `${styles["main-nav__item"]} ${styles["main-nav__item--cta"]}`;

    return (
        <header className={styles["main-header"]}>
            <Link to="/" className={styles["main-header_brand"]}>
                <img src={require(`../../../../img/logo.webp`)} alt="logo" />
            </Link>
            <button
                className={styles["toggle-button"]}
                type="button"
                onClick={manageMobileNav}
                id="mobile-nav__button"
                aria-label="mobile-nav-button"
            >
                <span className={styles["toggle-button__bar"]}></span>
                <span className={styles["toggle-button__bar"]}></span>
                <span className={styles["toggle-button__bar"]}></span>
            </button>

            <CSSTransition
                nodeRef={nodeRef}
                in={showMobileNav}
                timeout={400}
                mountOnEnter
                unmountOnExit
                classNames={{
                    enter: "",
                    enterActive: styles["mobile-backdrop__open"],
                    exit: "",
                    exitActive: styles["mobile-backdrop__close"],
                }}
            >
                <div
                    className={styles["mobile-backdrop"]}
                    onClick={manageMobileNav}
                    ref={nodeRef}
                />
            </CSSTransition>
            <CSSTransition
                nodeRef={nodeRef}
                in={showMobileNav}
                timeout={400}
                mountOnEnter
                unmountOnExit
                classNames={{
                    enter: "",
                    enterActive: styles["mobile-nav__open"],
                    exit: "",
                    exitActive: styles["mobile-nav__close"],
                }}
            >
                <nav className={styles["mobile-nav"]} ref={nodeRef}>
                    <button
                        className={styles["mobile-nav__exit"]}
                        type="button"
                        onClick={manageMobileNav}
                        id="mobile-nav__button--exit"
                        aria-label="mobile-nav-button-exit"
                    >
                        <div className={styles["mobile-nav__button--x"]}/>
                    </button>
                    <ul className={styles["mobile-nav__items"]}>
                        <li
                            className={styles["mobile-nav__item"]}
                            onClick={manageMobileNav}
                        >
                            <Link to="/">Start</Link>
                        </li>
                        <li
                            className={styles["mobile-nav__item"]}
                            onClick={manageMobileNav}
                        >
                            <Link to="/services">Leistungen</Link>
                        </li>
                        <li
                            className={styles["mobile-nav__item"]}
                            onClick={manageMobileNav}
                        >
                            <Link to="/products">Produkte</Link>
                        </li>
                        {/* <li
                            className={styles["mobile-nav__item"]}
                            onClick={manageMobileNav}
                        >
                            <Link to="/team">Team</Link>
                        </li> */}
                        <li
                            className={styles["mobile-nav__item"]}
                            onClick={manageMobileNav}
                        >
                            <Link to="/jobs">Jobs</Link>
                        </li>
                        <li
                            className={`${styles["mobile-nav__item"]} ${styles["mobile-nav__item--cta"]}`}
                            onClick={manageMobileNav}
                        >
                            <Link to="/contact">Kontakt</Link>
                        </li>
                    </ul>
                </nav>
            </CSSTransition>

            <nav className={styles["main-nav"]}>
                <ul className={styles["main-nav__items"]}>
                    <li
                        className={navItemStartStyles}
                        onMouseEnter={onHoverStart}
                    >
                        <Link to="/">Start</Link>
                    </li>
                    <li
                        className={navItemServiceStyles}
                        onMouseEnter={onHoverService}
                    >
                        <Link to="/services">Leistungen</Link>
                    </li>
                    <li
                        className={navItemAboutusStyles}
                        onMouseEnter={onHoverAboutus}
                    >
                        <Link to="/products">Produkte</Link>
                    </li>
                    {/* <li
                        className={navItemTeamStyles}
                        onMouseEnter={onHoverTeam}
                    >
                        <Link to="/team">Team</Link>
                    </li> */}
                    <li
                        className={navItemJobsStyles}
                        onMouseEnter={onHoverJobs}
                    >
                        <Link to="/jobs">Jobs</Link>
                    </li>
                    <li
                        className={navItemContactStyles}
                        onMouseEnter={onHoverContact}
                    >
                        <Link to="/contact">Kontakt</Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default MainHeader;
