import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useRouteError } from "react-router-dom";
import postErrorSite from "./functions/postErrorSite";

export default function ErrorPage() {
    const error = useRouteError();
    const errortype = String(error.status) + " - " + String(error.statusText);

    useEffect(() => {
        (async () => {
            await postErrorSite(error.status);
        })();
    }, []);
    return (
        <React.Fragment>
            <Helmet>
                <title>Error</title>
                <meta name="errorpage" content="true" />
                <meta name="errortype" content={errortype} />
                <meta name="prerender-status-code" content={error.status} />
            </Helmet>
            <div id="error-page">
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>
                        {error.status} {error.statusText || error.message}
                    </i>
                </p>
            </div>
        </React.Fragment>
    );
}
