import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import styles from "./Service.module.css";

import ProjectForm from "../../Content/Project/ProjectForm";
import Head from "../../UI/Head/Head";
import Foot from "../../UI/Foot/Foot";

const Service = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>Dienstleistungen</title>
                <meta
                    name="description"
                    content="Prototyping | Entwicklung | Fertigung - MZT GmbH"
                />
                <meta name="revisit-after" content="1 days"></meta>
                <link rel="canonical" href="https://www.mzt-gmbh.de/services" />
            </Helmet>
            <Head>Unsere Dienstleistungen</Head>
            <div className={styles["background__light"]}>
                <div className={styles["service__image"]}>
                    <img
                        src={require(`../../../img/services.webp`)}
                        alt={props.alt}
                    />
                </div>
                <div className={styles["analisis"]}>
                    <h2 className={styles["analisis__head"]}>Prototyping</h2>
                    <div className={styles["analisis-block"]}>
                        <h3 className={styles["analisis-block__head"]}>
                            Planung
                        </h3>
                        <p className={styles["analisis-block__text"]}>
                            Sie haben ein Projekt vor Augen, aber Ihnen fehlt
                            der klare Plan, um es erfolgreich umzusetzen? Wir
                            stehen Ihnen gerne zur Seite und bieten Ihnen
                            maßgeschneiderte Beratung und Planung für Ihr
                            Vorhaben.
                            <br />
                            <br />
                            Unser Team von Experten beginnt mit einer
                            detaillierten Vorhabenbesprechung, um Ihre Ziele und
                            Anforderungen zu verstehen. Dabei legen wir
                            besonderen Wert auf eine effektive
                            Zeitmanagement-Abstimmung, um sicherzustellen, dass
                            das Projekt reibungslos verläuft und pünktlich
                            abgeschlossen wird.
                            <br />
                            <br />
                            Wir unterstützen Sie bei der Erstellung eines
                            Lastenhefts, um alle Anforderungen und Wünsche des
                            Projekts zu dokumentieren. Eine Risikoanalyse hilft
                            uns dabei, potenzielle Schwierigkeiten frühzeitig zu
                            erkennen und Lösungen zu entwickeln, um sie zu
                            vermeiden oder zu bewältigen.
                            <br />
                            <br />
                            Durch unsere Expertise und Erfahrung sind wir in der
                            Lage, Ihnen die besten Lösungsmöglichkeiten für Ihr
                            Projekt zu präsentieren. Wir beraten Sie bei der
                            Auswahl der richtigen Technologien und Tools und
                            helfen Ihnen, das Projekt auf den Weg zum Erfolg zu
                            bringen.
                            <br />
                            <br />
                            Wenn Sie eine professionelle und zuverlässige
                            Unterstützung für Ihr Projekt benötigen, sind wir
                            der ideale Partner für Sie. Kontaktieren Sie uns
                            noch heute, um mehr darüber zu erfahren, wie wir Sie
                            bei Ihrem Vorhaben unterstützen können.
                        </p>
                        <h3 className={styles["analisis-block__head"]}>
                            Analyse vorhandener Systeme
                        </h3>
                        <p className={styles["analisis-block__text"]}>
                            Sie haben ein innovatives Produkt entwickelt, doch
                            aus verschiedenen Gründen möchten Sie nicht mehr mit
                            dem ursprünglichen Entwicklungsbüro
                            zusammenarbeiten. Kein Problem! Wir stehen Ihnen zur
                            Seite und erarbeiten gemeinsam mit Ihnen das weitere
                            Vorgehen.
                            <br />
                            <br />
                            Je nachdem, welche Unterlagen Sie vorliegen haben,
                            prüfen wir, inwiefern wir diese übernehmen und
                            weiterführen können. Besitzen Sie bereits alle
                            erforderlichen Quellcodes und Schaltungsunterlagen,
                            sind wir in der Lage, das Produkt nahtlos
                            weiterzuentwickeln.
                            <br />
                            <br />
                            Falls keine Vereinbarung auf Lieferung der
                            Quellcodes mit dem Entwicklungsbüro getroffen wurde,
                            ist eine Anpassung des Produkts an den aktuellen
                            Stand der Technik oft unumgänglich. Gemeinsam
                            erarbeiten wir eine Lösung, damit Ihr Produkt auch
                            in Zukunft erfolgreich im Markt agieren kann.
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles["background__dark"]}>
                <div className={styles["development"]}>
                    <h2 className={styles["development__head"]}>Entwicklung</h2>
                    <div className={styles["development-block"]}>
                        <h3 className={styles["development-block__head"]}>
                            Hardware
                        </h3>
                        <p className={styles["development-block__text"]}>
                            Wenn Sie auf der Suche nach einem erfahrenen
                            Elektronikentwicklungsunternehmen sind, das Ihnen
                            bei der Schaltplanerstellung und der EMV-gerechten
                            Layouterstellung hilft, dann sind Sie bei uns genau
                            richtig.
                            <br />
                            <br />
                            Wir bieten Ihnen eine breite Palette an
                            Dienstleistungen an, einschließlich der
                            Schaltplanerstellung, der Layouterstellung und der
                            Integration verschiedener µController (8 und 32 Bit)
                            sowie verschiedener Bussysteme wie I2C, RS485, USB
                            usw.
                            <br />
                            <br />
                            Unsere erfahrenen Ingenieure und Techniker verfügen
                            über umfangreiche Kenntnisse in der
                            Elektronikentwicklung und setzen modernste
                            Technologien ein, um sicherzustellen, dass Ihr
                            Produkt höchste EMV-Anforderungen erfüllt. Wir
                            sorgen dafür, dass jedes Detail berücksichtigt wird,
                            um sicherzustellen, dass Ihr Produkt optimal
                            funktioniert und den höchsten Qualitätsstandards
                            entspricht.
                            <br />
                            <br />
                            Unsere Flexibilität und unser breites Know-how
                            ermöglichen es uns, Elektronikprojekte für
                            verschiedene Branchen und Anwendungen zu entwickeln.
                            Ob Sie ein kleines Start-up oder ein etabliertes
                            Unternehmen sind, wir helfen Ihnen gerne bei der
                            Entwicklung Ihrer Ideen und bringen Ihr Produkt auf
                            den Markt.
                            <br />
                            <br />
                            Wir legen Wert auf eine enge Zusammenarbeit mit
                            unseren Kunden und stellen sicher, dass wir Ihre
                            Anforderungen und Erwartungen verstehen. Wir
                            arbeiten mit Ihnen zusammen, um sicherzustellen,
                            dass Ihr Produkt optimal auf Ihre Bedürfnisse
                            zugeschnitten ist.
                            <br />
                            <br />
                            Wenn Sie auf der Suche nach einem zuverlässigen
                            Partner für die Elektronikentwicklung sind, sind wir
                            die perfekte Wahl für Sie. Kontaktieren Sie uns noch
                            heute, um mehr darüber zu erfahren, wie wir Ihnen
                            helfen können. Wir freuen uns darauf, mit Ihnen
                            zusammenzuarbeiten.
                        </p>
                        <h3 className={styles["development-block__head"]}>
                            Software
                        </h3>
                        <p className={styles["development-block__text"]}>
                            Wir bieten Ihnen maßgeschneiderte Softwarelösungen
                            für Ihre embedded Systeme in Basic, C oder C++ und
                            können Ihnen dabei helfen, Ihre Geräte intelligent
                            und effektiv zu gestalten.
                            <br />
                            <br />
                            Darüber hinaus haben wir Kenntnisse in der embedded
                            Linux-Entwicklung und bieten Ihnen Web- und
                            App-Interfaces für Ihre Geräte an. Mit unserer
                            Expertise in der Elektronikentwicklung,
                            Schnittstellenmanagement, Backend- und
                            Frontendentwicklung erhalten sie eine vollständige
                            Lösung aus einer Hand, um ein nahtloses
                            Benutzererlebnis bieten zu können.
                            <br />
                            <br />
                            Unser Team hat auch Erfahrung mit der einfachen
                            Hosting-Möglichkeit über Docker und kann Ihnen dabei
                            helfen, Ihre Anwendungen problemlos zu skalieren.
                            Wir sind bestrebt, die besten Lösungen für Ihre
                            Bedürfnisse zu finden und Ihnen dabei zu helfen,
                            Ihre Produkte erfolgreich auf den Markt zu bringen.
                        </p>
                        <h3 className={styles["development-block__head"]}>
                            Konstruktion
                        </h3>
                        <p
                            className={`${styles["development-block__text"]} ${styles["development-block__text--last"]}`}
                        >
                            Wir bieten Ihnen eine professionelle 3D-Konstruktion
                            für Einzelteile und Baugruppen, sowie 2D
                            Maßzeichnungen und Baugruppenzeichnungen als
                            Montageanleitung. Unser Team hat langjährige
                            Erfahrung in der Konstruktion für spanende und
                            additive Fertigung und kann somit auf Ihre
                            individuellen Bedürfnisse eingehen.
                            <br />
                            <br />
                            Um Ihnen eine realistische Darstellung Ihrer
                            Produkte zu bieten, erstellen wir für Sie auch
                            Renderings für Webseiten und Kataloge. Dabei legen
                            wir Wert auf hohe Qualität und Präzision, um Ihre
                            Produkte bestmöglich darzustellen.
                        </p>
                    </div>
                    <ProjectForm
                        headline="Referenzentwicklungen"
                        projects={props.referenceProjects}
                    />
                </div>
            </div>

            <div className={styles["background__light"]}>
                <div className={styles["production"]}>
                    <h2 className={styles["production__head"]}>Fertigung</h2>
                    <div className={styles["production-block"]}>
                        <p className={styles["production-block__text"]}>
                            Wir bieten Ihnen nicht nur eine effiziente
                            Serienfertigung, sondern auch maßgeschneiderte
                            Lösungen für Ihre Einzelstückfertigung. So können
                            wir Ihren Anforderungen in jeder Phase gerecht
                            werden.
                            <br />
                            <br />
                            Um sicherzustellen, dass alle Anforderungen erfüllt
                            werden, legen wir großen Wert auf eine sorgfältige
                            Dokumentation. So behalten Sie stets den Überblick
                            über den Fortschritt Ihres Projekts.
                            <br />
                            <br />
                            Unsere langjährige Erfahrung und unser Know-how
                            ermöglichen es uns, Ihre Produkte erfolgreich auf
                            dem Markt einzuführen. Lassen Sie uns gemeinsam an
                            Ihrem Erfolg arbeiten und Ihre Ideen in die Realität
                            umsetzen.
                        </p>
                    </div>
                </div>
            </div>

            <Foot
                headline="Interesse geweckt?"
                path="/contact"
                btnText="Kontakt"
            >
                Sie haben eine spannende Projektidee, brauchen Hilfe bei dem
                Schritt zur Serienreife oder suchen alternative Produktionswege?
                <br />
                <br />
                Zögern Sie nicht, uns zu kontaktieren!
            </Foot>
        </React.Fragment>
    );
};

export default Service;
