import React from "react";

import styles from './Head.module.css'

const Head = (props) => {
    return (
        <div className={styles.background}>
            <h1>{props.children}</h1>
        </div>
    );
};

export default Head;