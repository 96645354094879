import React, { useState, useEffect} from "react";

import fetchProjects from "../functions/fetchProjects";
import fetchTeam from "../functions/fetchTeam";
import fetchJobs from "../functions/fetchJobs";

const AppContext = React.createContext({
    currentProjects: [],
    pastProjects: [],
    featuredProjects: [],
    newProjects: [],
    teamLeader: [],
    fullTeam: [],
    devJobs: [],
});

export function AppContextProvider(props) {
    const [pastProjectData, setpastProjectData] = useState([]);
    const [featuredProjectData, setFeaturedProjectData] = useState([]);
    const [currentProjectData, setCurrentProjectData] = useState([]);
    const [newProjectData, setNewProjectData] = useState([]);
    const [referenceProjectData, setReferenceProjectData] = useState([]);

    const [teamLeader, setTeamLeader] = useState([]);
    const [fullTeam, setFullTeam] = useState([]);

    const [devJobs, setDevJobs] = useState([]);
    const [ctrlJobs, setCtrlJobs] = useState([]);
    const [prdctJobs, setPrdctJobs] = useState([]);

    useEffect(() => {
        (async () => {
            const projectData = await fetchProjects();
            setpastProjectData((pastProjectData) => projectData.pastProjects);    
            setFeaturedProjectData((featuredProjectData) => projectData.featuredProjects);
            setCurrentProjectData((currentProjectData) => projectData.currentProjects);
            setNewProjectData((newProjectData) => projectData.newProjects);
            setReferenceProjectData((referenceProjectData) => projectData.referenceProjects);
        })();
        (async () => {
            const team = await fetchTeam();
            setTeamLeader((teamLeader) => team.teamLeader);    
            setFullTeam((fullTeam) => team.fullTeam);
        })();
        (async () => {
            const jobData = await fetchJobs();
            setDevJobs((devJobs) => jobData.devJobs);
            setCtrlJobs((ctrlJobs) => jobData.ctrlJobs);
            setPrdctJobs((prdctJobs) => jobData.prdctJobs);
        })();
    }, []);

    return (
        <AppContext.Provider
            value={{
                featuredProjects: featuredProjectData,
                pastProjects: pastProjectData,
                currentProjects: currentProjectData,
                newProjects: newProjectData,
                referenceProjects: referenceProjectData,
                teamLeader: teamLeader,
                fullTeam: fullTeam,
                devJobs: devJobs,
                ctrlJobs: ctrlJobs,
                prdctJobs: prdctJobs,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}

export default AppContext;