import axios from "axios";
import { API_URL } from "./apiURL";

// async function postJobForm(title, name, mail, letterData, cvData) {
//     const formData = new FormData();

//     formData.append("Title", title);
//     formData.append("Name", name);
//     formData.append("Mail", mail);
//     formData.append("Letter", letterData);
//     formData.append("CV", cvData);

//     return fetch("http://webseite-flask:5000/job_form", {
//         method: "POST",
//         body: formData,
//     })
//         .then((response) => response.json())
//         .then((result) => {
//             console.log("success:", result);
//         })
//         .catch((error) => {
//             console.error("error:", error);
//         });
// }

async function postErrorSite(errorCode) {
    const formData = new FormData();

    formData.append("errorCode", errorCode);

    const response = await axios.post(`${API_URL}/error`, formData)

    return response.data;
}

export default postErrorSite;
