import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import styles from "./ProjectForm.module.css";
import Project from "./Project";
import ArrowSwitch from "../../UI/ArrowSwitch/ArrowSwitch";
import Button from "../../UI/Button/Button";

const ProjectForm = (props) => {
    const [activeProject, setActiveProject] = useState(0);
    const [buttonHovered, setButtonHovered] = useState(false);

    const changeProjectNext = () => {
        var newActive = activeProject;

        newActive++;
        if (newActive >= props.projects.length) newActive = 0;

        setActiveProject(newActive);
    };
    const changeProjectPrev = () => {
        var newActive = activeProject;

        newActive--;
        if (newActive < 0) newActive = props.projects.length - 1;

        setActiveProject(newActive);
    };

    const onHoverButton = () => {
        if(!buttonHovered) setButtonHovered(true);
    }

    const buttonStyles = buttonHovered ? styles["btn-hovered"] : styles["btn"];

    return (
        <React.Fragment>
            {props.projects[activeProject] && (
                <div className={styles.container}>
                    {props.headline && <h3>{props.headline}</h3>}
                    {props.projects.length === 1 && (
                        <React.Fragment>
                            <Project
                                title={props.projects[0].title}
                                features={props.projects[0].description}
                                alt={props.projects[0].title}
                                images={props.projects[0].images}
                                imgFolder={props.projects[0].imgFolder}
                                plugCheck={props.projects[0].plugCheck}
                                pwm={props.projects[0].pwm}
                                phases={props.projects[0].phases}
                                cables={props.projects[0].cables}
                                pp={props.projects[0].pp}
                                cp={props.projects[0].cp}
                                errors={props.projects[0].errors}
                                automation={props.projects[0].automation}
                                design={props.projects[0].design}
                                layman={props.projects[0].layman}
                            >
                                {props.projects[0].description}
                            </Project>
                            {props.projects[0].link && (
                                <a
                                    href={props.projects[0].link}
                                    className={styles.ref}
                                >
                                    <Button
                                        className={buttonStyles}
                                        onMouseEnter={onHoverButton}
                                        disabled={false}
                                    >
                                        Mehr Informationen
                                    </Button>
                                </a>
                            )}
                        </React.Fragment>
                    )}
                    {props.projects.length > 1 && (
                        <Swiper
                            className={styles["projects"]}
                            spaceBetween={50}
                            loop={true}
                            onSlideNextTransitionEnd={changeProjectNext}
                            onSlidePrevTransitionEnd={changeProjectPrev}
                            onAfterInit={() => setActiveProject(0)}
                        >
                            {props.projects.map((project, index) => (
                                <SwiperSlide key={index}>
                                    <Project
                                        title={project.title}
                                        features={project.description}
                                        images={project.images}
                                        imgFolder={project.imgFolder}
                                        alt={project.title}
                                        plugCheck={project.plugCheck}
                                        pwm={project.pwm}
                                        phases={project.phases}
                                        cables={project.cables}
                                        pp={project.pp}
                                        cp={project.cp}
                                        errors={project.errors}
                                        automation={project.automation}
                                        design={project.design}
                                        layman={project.layman}
                                    >
                                        {project.description}
                                    </Project>
                                </SwiperSlide>
                            ))}
                            {props.projects[activeProject].link && (
                                <a
                                    href={props.projects[activeProject].link}
                                    className={styles.ref}
                                >
                                    <Button
                                        className={buttonStyles}
                                        onMouseEnter={onHoverButton}
                                        disabled={false}
                                    >
                                        Mehr Informationen
                                    </Button>
                                </a>
                            )}

                            <ArrowSwitch
                                projectNumber={props.projects.length}
                                activeProject={activeProject}
                            />
                        </Swiper>
                    )}
                </div>
            )}
            {!props.projects[activeProject] && !props.filtered && (
                <React.Fragment>
                    <div className={styles.backdrop} />
                    <div className={styles["lds-ring"]}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </React.Fragment>
            )}
            {!props.projects[activeProject] && props.filtered && (
                <div className={styles.container}>
                    <h3>Diese Konfiguration ist derzeit nicht verfügbar</h3>

                    <a href="#/contact" className={styles.ref}>
                        <Button className={styles.btn} disabled={false}>
                            Wunschkonfiguration mitteilen
                        </Button>
                    </a>
                </div>
            )}
        </React.Fragment>
    );
};

export default ProjectForm;
