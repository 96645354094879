import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import styles from "./Home.module.css";

import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import ProjectForm from "../../Content/Project/ProjectForm";
import Project from "../../Content/Project/Project";
import PersonForm from "../../Content/Person/PersonForm";
import Foot from "../../UI/Foot/Foot";

const Home = (props) => {
    const [headButtonHovered, setHeadButtonHovered] = useState(false);
    const [servicesButtonHovered, setServicesButtonHovered] = useState(false);
    const [productsButtonHovered, setProductsButtonHovered] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const goToContact = () => {
        let path = "/contact";
        window.scrollTo(0, 0);
        navigate(path);
    };
    const goToProducts = () => {
        let path = "/products";
        window.scrollTo(0, 0);
        navigate(path);
    };
    const goToServices = () => {
        let path = "/services";
        window.scrollTo(0, 0);
        navigate(path);
    };

    const onHeadButtonHover = () => {
        if (!headButtonHovered) setHeadButtonHovered(true);
    };
    const onProductsButtonHover = () => {
        if (!productsButtonHovered) setProductsButtonHovered(true);
    };
    const onServicesButtonHover = () => {
        if (!servicesButtonHovered) setServicesButtonHovered(true);
    };

    const headButtonStyles = headButtonHovered
        ? styles["btn-hovered"]
        : styles["btn"];
    const productsButtonStyles = productsButtonHovered
        ? styles["btn-hovered"]
        : styles["btn"];
    const servicesButtonStyles = servicesButtonHovered
        ? styles["serviceBtn-hovered"]
        : styles["serviceBtn"];

    return (
        <React.Fragment>
            <Helmet>
                <title>Elektronikentwicklung und Fertigung aus einer Hand | MZT GmbH</title>
                <meta
                    name="description"
                    content="Wir sind ein Mechatronik-Unternehmen, spezialisiert in den Bereichen Elektromobilität und Elektronische Systemlösungen | Witten"
                />
                <meta name="revisit-after" content="1 days" />
                <link rel="canonical" href="https://www.mzt-gmbh.de/" />
            </Helmet>
            <div className={styles["background__dark"]}>
                <div className={styles["siteHead"]}>
                    <h1 className={styles["siteHead__head"]}>
                        Elektronikentwicklung und Fertigung aus einer Hand
                    </h1>
                    <p className={styles["siteHead__text"]}>
                        Sie suchen einen zuverlässigen Partner für die
                        Realisierung Ihrer Projektidee? <br />
                        <br />
                        Sie brauchen Hilfe bei dem letzten Schritt zur
                        Serienreife?
                        <br />
                        <br />
                        Sie kommen über den Schritt der Idee nicht hinaus oder
                        sind unsicher bezüglich der Machbarkeit?
                        <br />
                        <br />
                        Sie suchen schnelle und effiziente Hilfe bei der
                        Umsetzung?
                        <br /> <br />
                        Dann sind Sie bei uns genau richtig!
                    </p>
                    <Button
                        type="button"
                        className={headButtonStyles}
                        onClick={goToContact}
                        onMouseEnter={onHeadButtonHover}
                        disabled={false}
                    >
                        Anfrage stellen
                    </Button>
                    <Card className={styles["siteHead__quote"]}>
                        "Die Technologie ist der Treiber der Innovation. Ohne
                        sie wären wir immer noch in Höhlen und würden uns mit
                        Steinen bewerfen." <br />
                        <br />- Bill Gates
                    </Card>
                </div>
            </div>
            <div className={styles["background__light"]}>
                <div className={styles["service"]}>
                    <h2 className={styles["service__head"]}>
                        Produktentwicklung aus Leidenschaft
                    </h2>
                    <p className={styles["service__text"]}>
                        Wir sind ein Mechatronik-Unternehmen, das sich in den
                        Bereichen Elektromobilität, sowie Elektronische
                        Systemlösungen spezialisiert hat. Wir haben uns in den
                        letzten 25 Jahren von einem Ingenieurbüro zu einem
                        innovativen Entwicklungs- und Fertigungspartner
                        entwickelt und bieten unseren Kunden individuelle
                        Fertigungslösungen, effiziente Produktoptimierung und
                        die Lösung komplexer Realisierungsprobleme. <br />
                        <br />
                        Wir sind ein OEM-Partner und entwickeln seit 2009
                        kontinuierlich mechatronische Lösungen für die
                        Elektromobilitätsindustrie. Unser Motto lautet "aus der
                        Praxis für die Praxis". Wir sammeln täglich neue Ideen
                        und Erfahrungen aus der Entwicklung, Fertigung und
                        Anwendung von E-Mobility-Lösungen und nutzen diese, um
                        gemeinsam mit unseren Kunden Herausforderungen zu meistern.
                        <br />
                        Zusätzlich zu unserer Expertise in der E-Mobility können
                        Sie sich mit unseren fortschrittlichen IOT und
                        Predictive Maintenance Lösungen auf effiziente und
                        zuverlässige Umsetzung für Ihre Industrie verlassen.{" "}
                        <br />
                        <br /> Wir sind führend in Sachen Flexibilität,
                        Reaktionsgeschwindigkeit und Entwicklungskreativität.
                        Zusätzlich zu unseren Kernleistungen bieten wir eine
                        Vielzahl von Dienstleistungen vor und nach der
                        Produktion. Mit unserem breiten Angebot finden Sie immer
                        die für Sie passende Lösung aus einer Hand. Unsere
                        langjährigen Kundenbeziehungen zeigen das Vertrauen, das
                        in uns gesetzt wird, und bestätigen uns in unserem
                        Handeln. Unser Know-how ist Ihr Wettbewerbsvorteil.
                        <br />
                        <br />
                        Exemplarisch für die vielen Möglichkeiten, die wir
                        bieten können, stellen wir Ihnen unsere
                        Ladesäulensteuerung vor. Sie wird für eine Vielzahl von
                        Ladesäulentypen eingesetzt und wurde über viele Jahre
                        abwärtskompatibel nach den Anforderungen des Marktes
                        weiterentwickelt. Durch eine interdisziplinäre
                        Zusammenarbeit mit einem namenhaften Ladesäulen
                        Hersteller und unsere Expertise in embedded Hard- und
                        Softwareentwicklung, bieten wir eine technologisch
                        fortschrittliche Lösung.
                    </p>
                    <Button
                        type="button"
                        className={servicesButtonStyles}
                        onClick={goToServices}
                        onMouseEnter={onServicesButtonHover}
                        disabled={false}
                    >
                        Unsere Leistungen
                    </Button>
                    <ProjectForm projects={props.featuredProjects} />
                </div>
            </div>
            <div className={styles["background__dark"]}>
                <div className={styles["projects"]}>
                    <h2 className={styles["projects__head"]}>
                        Prüftechnik für E-Mobility
                    </h2>
                    <p className={styles["projects__text"]}>
                        Dank unserer jahrelangen und intensiven
                        Auseinandersetzung mit dem Thema Elektromobilität haben
                        wir uns als Experten in der Branche etabliert. Wir haben
                        Problemstellungen identifiziert, die wir in innovative
                        Prüf- und Diagnosegeräte umgesetzt haben. Aus unserem
                        Wissen sind uns bahnbrechende Produktideen gekommen.{" "}
                        <br />
                        <br />
                        In Zusammenarbeit mit unserem langjährigen Partner
                        Gossen Metrawatt bringen wir heute erfolgreich unsere
                        innovativen Produkte auf den Markt. Wir sind stolz
                        darauf, dass unsere Produkte kontinuierlich verbessert
                        werden und den Anforderungen der Branche angepasst sind.
                        <br />
                        <br />
                        Unsere neue Produktlinie überzeugt nicht nur mit ihrer
                        technischen Qualität, sondern auch mit einem
                        einheitlichen und ansprechenden Design. Wir setzen auf
                        klare Formen und eine intuitive Bedienung, um Ihnen eine
                        nahtlose und reibungslose Arbeitsweise zu ermöglichen.
                        <br />
                        <br />
                        Wenn Sie nach den neuesten Innovationen im Bereich der
                        Elektromobilität suchen, sind wir der perfekte Partner
                        für Sie. Wir bieten Ihnen eine große Auswahl an
                        Produkten, die auf Ihre spezifischen Anforderungen
                        abgestimmt sind. Werden Sie Teil der Erfolgsgeschichte
                        und setzen Sie auf unsere Expertise in der
                        Elektromobilitätsbranche.
                    </p>
                    <Button
                        type="button"
                        className={productsButtonStyles}
                        onClick={goToProducts}
                        onMouseEnter={onProductsButtonHover}
                        disabled={false}
                    >
                        Unsere Produkte
                    </Button>
                    {props.newProjects[0] && (
                        <Project
                            title={props.newProjects[0].title}
                            features={props.newProjects[0].description}
                            images={props.newProjects[0].images}
                            imgFolder={props.newProjects[0].imgFolder}
                            alt={props.newProjects[0].title}
                            background="dark"
                        >
                            {props.newProjects[0].description}
                        </Project>
                    )}
                    <br />
                    {props.newProjects[1] && (
                        <Project
                            title={props.newProjects[1].title}
                            features={props.newProjects[1].description}
                            images={props.newProjects[1].images}
                            imgFolder={props.newProjects[1].imgFolder}
                            alt={props.newProjects[1].title}
                            background="dark"
                        >
                            {props.newProjects[1].description}
                        </Project>
                    )}
                </div>
            </div>

            <div className={styles["background__light"]}>
                <div className={styles["infos"]}>
                    <h2 className={styles["infos__head"]}>Leitung & Partner</h2>
                    <PersonForm persons={props.teamLeader} />
                    <div className={styles["infos-partner"]}>
                        <a
                            href="https://www.gmc-instruments.de/"
                            className={styles["infos-partner__image"]}
                        >
                            <img
                                src={require(`../../../img/gmc-logo.webp`)}
                                alt="logo"
                            />
                        </a>

                        <a
                            href="https://ebg-group.com/"
                            className={styles["infos-partner__image"]}
                        >
                            <img
                                src={require(`../../../img/ebggroup-logo.webp`)}
                                alt="logo"
                            />
                        </a>
                        <a
                            href="https://sw-machines.com/"
                            className={styles["infos-partner__image"]}
                        >
                            <img
                                src={require(`../../../img/swmachines-logo.webp`)}
                                alt="logo"
                            />
                        </a>
                        <a
                            href="https://www.compleo-charging.com/"
                            className={styles["infos-partner__image"]}
                        >
                            <img
                                src={require(`../../../img/compleo-logo.webp`)}
                                alt="logo"
                            />
                        </a>
                    </div>
                </div>
            </div>

            <Foot
                headline="Interesse geweckt?"
                path="/contact"
                btnText="Kontakt"
            >
                Wir haben Ihre Aufmerksamkeit gewonnen, Sie finden aber nicht
                wonach Sie gesucht haben, oder hätten gerne einen persönlichen
                Ansprechpartner? <br />
                <br />
                Zögern Sie nicht, uns zu kontaktieren!
            </Foot>
        </React.Fragment>
    );
};

export default Home;
