import React from "react";

import styles from "./Person.module.css";

const Person = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.image}>
                <img
                    src={require(`../../../img/team/${props.img}`)}
                    alt={props.name}
                />
            </div>
            <div className={styles["description"]}>
                <h4>{props.name}</h4>
                <h5>{props.departement}</h5>
                <p>{props.children}</p>
            </div>
        </div>
    );
};

export default Person;
