import React, {useState} from "react";

import styles from './ArrowBox.module.css';

const ArrowBox = (props) => {
    const [arrowHovered, setArrowHovered] = useState(false);

    const onHoverArrow = () => {
        if(!arrowHovered) setArrowHovered(true);
    }

    const arrowStyles = arrowHovered ? styles["arrowBox-hovered"] : styles["arrowBox"];

    return (
        <div className={arrowStyles} onClick={props.onClick} data-direction={props.direction} onMouseEnter={onHoverArrow}>
            <i className={`${styles.arrow} || ${styles[props.direction]} || ${props.arrow}`}  data-direction={props.direction}/>
        </div>
    );
};

export default ArrowBox;