import React, { useState, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";

import postJobForm from "../../../functions/postJobForm";
import {
    useInputText,
    useInputCheckbox,
    useInputFile,
} from "../../../hooks/use-input";

import styles from "./JobForm.module.css";

import Button from "../../UI/Button/Button";
import FileUpload from "../../UI/FileUpload/FileUpload";

const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => value.includes("@") && value.includes(".");
const isChecked = (value) => value;
const isFile = (value) => value !== null;

const JobForm = (props) => {
    const [submitButtonHovered, setSubmitButtonHovered] = useState(false);
    const [cancelButtonHovered, setCancelButtonHovered] = useState(false);
    const [messageButtonHovered, setMessageButtonHovered] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [didSubmit, setDidSubmit] = useState(false);
    const [showBackdropOverlay, setShowBackropOverlay] = useState(false);

    const nodeRef = useRef(null);

    const {
        value: enteredName,
        isValid: enteredNameIsValid,
        hasError: nameInputHasError,
        valueChangeHandler: nameChangeHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetName,
    } = useInputText(isNotEmpty);

    const {
        value: enteredMail,
        isValid: enteredMailIsValid,
        hasError: mailInputHasError,
        valueChangeHandler: mailChangeHandler,
        inputBlurHandler: mailBlurHandler,
        reset: resetMail,
    } = useInputText(isEmail);

    const {
        value: enteredCheck,
        isValid: enteredCheckIsValid,
        hasError: checkInputHasError,
        valueChangeHandler: checkChangeHandler,
        inputBlurHandler: checkBlurHandler,
        reset: resetCheck,
    } = useInputCheckbox(isChecked);

    const {
        value: enteredLetter,
        isValid: enteredLetterIsValid,
        hasError: letterInputHasError,
        valueChangeHandler: letterChangeHandler,
        inputBlurHandler: letterBlurHandler,
        reset: resetLetter,
    } = useInputFile(isFile);

    const {
        value: enteredCv,
        isValid: enteredCvIsValid,
        hasError: cvInputHasError,
        valueChangeHandler: cvChangeHandler,
        inputBlurHandler: cvBlurHandler,
        reset: resetCv,
    } = useInputFile(isFile);

    const formIsValid =
        enteredNameIsValid &&
        enteredMailIsValid &&
        enteredCheckIsValid &&
        enteredCvIsValid;

    const formSubmissionHandler = async (event) => {
        event.preventDefault();

        if (!formIsValid) {
            return;
        }

        resetName();
        resetMail();
        resetLetter();
        resetCv();
        resetCheck();

        setIsSubmitting(true);
        setShowBackropOverlay(true);

        const response = await postJobForm(
            props.jobTitle,
            enteredName,
            enteredMail,
            enteredLetter,
            enteredCv
        );

        setIsSubmitting(false);
        setDidSubmit(true);
        setSubmitButtonHovered(false);
    };

    const dismissMessage = () => {
        props.onCloseForm();
        setDidSubmit(false);
        setShowBackropOverlay(false);
    };

    const onHoverSubmit = () => {
        if (!submitButtonHovered) setSubmitButtonHovered(true);
    };
    const onHoverCancel = () => {
        if (!cancelButtonHovered) setCancelButtonHovered(true);
    };
    const onHoverMessageButton = () => {
        if (!messageButtonHovered) setMessageButtonHovered(true);
    };

    const submitButtonStyles = submitButtonHovered
        ? styles["btn-hovered"]
        : styles["btn"];
    const cancelButtonStyles = cancelButtonHovered
        ? styles["btn-hovered"]
        : styles["btn"];
    const messageButtonStyles = messageButtonHovered
        ? styles["btn-hovered"]
        : styles["btn"];

    const nameInputClasses = nameInputHasError
        ? `${styles["form-control"]} ${styles["invalid"]}`
        : styles["form-control"];

    const mailInputClasses = mailInputHasError
        ? `${styles["form-control"]} ${styles["invalid"]}`
        : styles["form-control"];

    return (
        <React.Fragment>
            <CSSTransition
                nodeRef={nodeRef}
                in={props.show}
                timeout={600}
                mountOnEnter
                unmountOnExit
                classNames={{
                    enter: "",
                    enterActive: styles["backdrop__open"],
                    exit: "",
                    exitActive: styles["backdrop__close"],
                }}
            >
                <div className={styles.backdrop} ref={nodeRef} />
            </CSSTransition>
            <CSSTransition
                nodeRef={nodeRef}
                in={showBackdropOverlay}
                timeout={400}
                mountOnEnter
                unmountOnExit
                classNames={{
                    enter: "",
                    enterActive: styles["backdrop__open"],
                    exit: "",
                    exitActive: styles["backdrop__close"],
                }}
            >
                <div className={styles["backdrop-overlay"]} ref={nodeRef} />
            </CSSTransition>
            {isSubmitting && (
                <div>
                    <div className={styles["lds-ring"]}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <CSSTransition
                nodeRef={nodeRef}
                in={didSubmit}
                timeout={600}
                mountOnEnter
                unmountOnExit
                classNames={{
                    enter: "",
                    enterActive: styles["card__open"],
                    exit: "",
                    exitActive: styles["card__close"],
                }}
            >
                <div className={styles.card} ref={nodeRef}>
                    <div>
                        <p>
                            Vielen Dank für die Einsendung der Bewerbung. <br />{" "}
                            Wir setzen uns zeitnah mit Ihnen in Verbindung
                        </p>
                    </div>
                    <Button
                        className={messageButtonStyles}
                        onMouseEnter={onHoverMessageButton}
                        onClick={dismissMessage}
                    >
                        Schließen
                    </Button>
                </div>
            </CSSTransition>
            <CSSTransition
                nodeRef={nodeRef}
                in={props.show}
                timeout={600}
                mountOnEnter
                unmountOnExit
                classNames={{
                    enter: "",
                    enterActive: styles["form__open"],
                    exit: "",
                    exitActive: styles["form__close"],
                }}
            >
                <form
                    className={styles.form}
                    onSubmit={formSubmissionHandler}
                    ref={nodeRef}
                >
                    <div className={nameInputClasses}>
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={enteredName}
                            onChange={nameChangeHandler}
                            onBlur={nameBlurHandler}
                        />
                        {nameInputHasError && (
                            <p className={styles["error-text"]}>
                                Bitte einen Namen eingeben
                            </p>
                        )}
                    </div>
                    <div className={mailInputClasses}>
                        <label htmlFor="mail">E-Mail</label>
                        <input
                            type="text"
                            id="mail"
                            value={enteredMail}
                            onChange={mailChangeHandler}
                            onBlur={mailBlurHandler}
                        />
                        {mailInputHasError && (
                            <p className={styles["error-text"]}>
                                Bitte eine gültige Email Adresse eingeben
                            </p>
                        )}
                    </div>

                    <div className={styles["form-control"]}>
                        <label
                            className={styles["form-control__documents"]}
                            htmlFor="documents"
                        >
                            Dokumente (.pdf, .docx)
                        </label>
                    </div>

                    <FileUpload
                        handleChange={letterChangeHandler}
                        handleBlur={letterBlurHandler}
                        file={enteredLetter}
                        label="Anschreiben (optional)"
                        htmlFor="letter"
                        id="letter"
                    />

                    <FileUpload
                        handleChange={cvChangeHandler}
                        handleBlur={cvBlurHandler}
                        file={enteredCv}
                        label="Lebenslauf"
                        htmlFor="cv"
                        id="cv"
                    />
                    {cvInputHasError && (
                        <p className={styles["error-text"]}>
                            Bitte einen Lebenslauf hochladen
                        </p>
                    )}

                    <div className={styles["form-control"]}>
                        <label htmlFor="check">Datenschutz</label>
                        <div className={styles.privacy}>
                            <input
                                type="checkbox"
                                id={styles.check}
                                checked={enteredCheck}
                                onChange={checkChangeHandler}
                                onBlur={checkBlurHandler}
                            />
                            <p>
                                Ich habe die{" "}
                                <Link to="/privacynotice">Datenschutzerklärung</Link>{" "}
                                gelesen und akzeptiere sie. Ich bin mit der Erhebung, Verarbeitung und Nutzung meiner oben angegebenen personenbezogenen Daten einverstanden.
                            </p>
                        </div>
                        {checkInputHasError && (
                            <p className={styles["error-text"]}>
                                Datenschutzerklärung nicht akzeptiert
                            </p>
                        )}
                    </div>

                    <div className={styles["buttons"]}>
                        <Button
                            className={cancelButtonStyles}
                            onMouseEnter={onHoverCancel}
                            disabled={false}
                            onClick={props.onCloseForm}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            type="submit"
                            className={submitButtonStyles}
                            onMouseEnter={onHoverSubmit}
                            disabled={!formIsValid}
                            onClick={formSubmissionHandler}
                        >
                            Absenden
                        </Button>
                    </div>
                </form>
            </CSSTransition>
        </React.Fragment>
    );
};

export default JobForm;
