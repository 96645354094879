import React from "react";
import { useSwiper } from "swiper/react";

import styles from "./ArrowSwitch.module.css";

import ArrowBox from "../ArrowBox/ArrowBox";
import Dot from "../Dot/Dot";

const ArrowSwitch = (props) => {

    const swiper = useSwiper();

    const projectChangeHandler = (event) => {
        if (event.target.dataset.direction === "right") swiper.slideNext();
        else swiper.slidePrev();
    };
    const getDots = () => {
        let dots = [];
        for (let i = 0; i < props.projectNumber; i++) {
            if (i === props.activeProject) {
                dots.push(<Dot key={i} active={true} />);
            } else {
                dots.push(<Dot key={i} active={false} />);
            }
        }
        return dots;
    };

    return (
        <div className={styles.container}>
            <ArrowBox direction="left" onClick={projectChangeHandler} />
            {getDots()}
            <ArrowBox direction="right" onClick={projectChangeHandler} />
        </div>
    );
};

export default ArrowSwitch;
