import React, { useState } from "react";
import { Helmet } from "react-helmet";

import styles from "./Jobs.module.css";

import Job from "./Job";
import JobForm from "./JobForm";
import Button from "../../UI/Button/Button";
import Head from "../../UI/Head/Head";
import Foot from "../../UI/Foot/Foot";

const Jobs = (props) => {
    const [buttonHovered, setButtonHovered] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [activeJob, setActiveJob] = useState(null);

    const onHoverButton = () => {
        if (!buttonHovered) setButtonHovered(true);
    };

    const formOpenHandler = (title) => {
        setActiveJob(title);

        setShowForm(true);
    };
    const formCloseHandler = () => {
        setShowForm(false);
    };

    const buttonStyles = buttonHovered ? styles["btn-hovered"] : styles["btn"];

    return (
        <React.Fragment>
            <Helmet>
                <title>Jobs</title>
                <meta name="description" content="Offene Stellen - MZT GmbH" />
                <meta name="revisit-after" content="1 days" />
                <link rel="canonical" href="https://www.mzt-gmbh.de/jobs" />
            </Helmet>
            <JobForm
                show={showForm}
                jobTitle={activeJob}
                onCloseForm={formCloseHandler}
            />
            <Head>Werde Teil des Teams</Head>
            <div className={styles.background}>
                <div className={styles.sect}>
                    <h2 id={styles.headline}>Offene Stellen</h2>
                    {props.devJobs.map((job, index) => (
                        <Job
                            key={index}
                            title={job.title}
                            time={job.time}
                            description={job.description}
                            qualificationText={job.qualificationText}
                            qualificationNecessary={job.qualificationNecessary}
                            qualificationOptional={job.qualificationOptional}
                            onOpenForm={formOpenHandler}
                        />
                    ))}
                    {props.ctrlJobs.map((job, index) => (
                        <Job
                            key={index}
                            title={job.title}
                            time={job.time}
                            description={job.description}
                            qualificationText={job.qualificationText}
                            qualificationNecessary={job.qualificationNecessary}
                            qualificationOptional={job.qualificationOptional}
                            onOpenForm={formOpenHandler}
                        />
                    ))}
                    {props.prdctJobs.map((job, index) => (
                        <Job
                            key={index}
                            title={job.title}
                            time={job.time}
                            description={job.description}
                            qualificationText={job.qualificationText}
                            qualificationNecessary={job.qualificationNecessary}
                            qualificationOptional={job.qualificationOptional}
                            onOpenForm={formOpenHandler}
                        />
                    ))}

                    <h3 id={styles.bottomline}>Nichts Passendes dabei?</h3>
                    <Button
                        className={buttonStyles}
                        onMouseEnter={onHoverButton}
                        disabled={false}
                        onClick={formOpenHandler}
                    >
                        Initiativbewerbung
                    </Button>
                </div>
            </div>
            <Foot
                headline="Interesse geweckt?"
                path="/contact"
                btnText="Kontakt"
            >
                Du bist dir unsicher ob du für eine Bewerbung geeignet bist,
                oder hast Hemmungen dich zu bewerben? <br />
                <br />
                Zögere nicht, dich ganz unbürokratisch direkt mit einer
                Nachricht an uns zu wenden!
            </Foot>
        </React.Fragment>
    );
};

export default Jobs;
