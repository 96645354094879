import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { CSSTransition } from "react-transition-group";

import { BsFillTelephoneFill } from "react-icons/bs";
import { FaLinkedin, FaXingSquare, FaMapMarker } from "react-icons/fa";
import { GrMail } from "react-icons/gr";

import styles from "./Contact.module.css";

import ContactForm from "./ContactForm";
import Head from "../../UI/Head/Head";
import Foot from "../../UI/Foot/Foot";

const Contact = (props) => {
    const addressRef = useRef(null);
    const xingRef = useRef(null);
    const phoneRef = useRef(null);
    const linkedinRef = useRef(null);
    const mailRef = useRef(null);

    const [contactFilter, setContactFilter] = useState({
        address: false,
        mail: false,
        phone: false,
        xing: false,
        linkedin: false,
    });
    const [contactHovered, setContactHovered] = useState({
        address: false,
        mail: false,
        phone: false,
        xing: false,
        linkedin: false,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onClickIcon = (event) => {
        var id = event.currentTarget.id;

        switch (id) {
            case "address":
                setContactFilter({
                    ...contactFilter,
                    address: contactFilter.address !== true,
                });
                break;

            case "mail":
                setContactFilter({
                    ...contactFilter,
                    mail: contactFilter.mail !== true,
                });
                break;

            case "phone":
                setContactFilter({
                    ...contactFilter,
                    phone: contactFilter.phone !== true,
                });
                break;

            case "xing":
                setContactFilter({
                    ...contactFilter,
                    xing: contactFilter.xing !== true,
                });
                break;

            case "linkedin":
                setContactFilter({
                    ...contactFilter,
                    linkedin: contactFilter.linkedin !== true,
                });
                break;
        }
    };

    const onHoverIcon = (event) => {
        var id = event.currentTarget.id;

        switch (id) {
            case "address":
                if (!contactHovered.address) {
                    setContactHovered({
                        ...contactHovered,
                        address: true,
                    });
                }
                break;

            case "mail":
                if (!contactHovered.mail) {
                    setContactHovered({
                        ...contactHovered,
                        mail: true,
                    });
                }
                break;

            case "phone":
                if (!contactHovered.phone) {
                    setContactHovered({
                        ...contactHovered,
                        phone: true,
                    });
                }
                break;

            case "xing":
                if (!contactHovered.xing) {
                    setContactHovered({
                        ...contactHovered,
                        xing: true,
                    });
                }
                break;

            case "linkedin":
                if (!contactHovered.linkedin) {
                    setContactHovered({
                        ...contactHovered,
                        linkedin: true,
                    });
                }
                break;
        }
    };

    const mailIconStyles = contactHovered.mail
        ? contactFilter.mail
            ? `${styles["contact__icon--clicked"]} ${styles["hovered--clicked"]}`
            : `${styles["contact__icon"]} ${styles["hovered"]}`
        : contactFilter.mail
        ? styles["contact__icon--clicked"]
        : styles["contact__icon"];

    const phoneIconStyles = contactHovered.phone
        ? contactFilter.phone
            ? `${styles["contact__icon--clicked"]} ${styles["hovered--clicked"]}`
            : `${styles["contact__icon"]} ${styles["hovered"]}`
        : contactFilter.phone
        ? styles["contact__icon--clicked"]
        : styles["contact__icon"];

    const addressIconStyles = contactHovered.address
        ? contactFilter.address
            ? `${styles["contact__icon--clicked"]} ${styles["hovered--clicked"]}`
            : `${styles["contact__icon"]} ${styles["hovered"]}`
        : contactFilter.address
        ? styles["contact__icon--clicked"]
        : styles["contact__icon"];

    const xingIconStyles = contactHovered.xing
        ? contactFilter.xing
            ? `${styles["contact__icon--clicked"]} ${styles["hovered--clicked"]}`
            : `${styles["contact__icon"]} ${styles["hovered"]}`
        : contactFilter.xing
        ? styles["contact__icon--clicked"]
        : styles["contact__icon"];

    const linkedinIconStyles = contactHovered.linkedin
        ? contactFilter.linkedin
            ? `${styles["contact__icon--clicked"]} ${styles["hovered--clicked"]}`
            : `${styles["contact__icon"]} ${styles["hovered"]}`
        : contactFilter.linkedin
        ? styles["contact__icon--clicked"]
        : styles["contact__icon"];

    return (
        <React.Fragment>
            <Helmet>
                <title>Kontakt</title>
                <meta name="description" content="Erreichen Sie uns schnell und unkompliziert - MZT Gmbh" />
                <meta name="revisit-after" content="1 days" />
                <link rel="canonical" href="https://www.mzt-gmbh.de/contact" />
            </Helmet>
            <Head>Interesse geweckt?</Head>

            <div className={styles.background}>
                <div className={styles.sect}>
                    <div className={styles.description}>
                        <h2>Unsere Kontaktinformationen</h2>
                        <p>
                            Wir freuen uns über Ihr Interesse! Wenn Sie Fragen
                            zu unseren Produkten oder Dienstleistungen haben
                            oder weitere Informationen benötigen, stehen wir
                            Ihnen gerne zur Verfügung, um Ihre Fragen zu
                            beantworten und Ihnen bei der Lösung Ihrer
                            Herausforderungen zu helfen. Ob Sie eine Beratung
                            benötigen, ein Angebot wünschen oder einfach nur
                            Kontakt mit uns aufnehmen möchten - wir sind für Sie
                            da. <br />
                            <br />
                            Bitte zögern Sie nicht, uns zu kontaktieren. Sie
                            erreichen uns über unser Kontaktformular. Alternativ
                            telefonisch, per E-Mail oder Social Media. Wir
                            werden uns schnellstmöglich bei Ihnen melden.
                        </p>
                    </div>
                    <div className={styles.body}>
                        <ContactForm />
                        <div className={styles.contact}>
                            <div className={styles["contact-block"]}>
                                <FaMapMarker
                                    id="address"
                                    size={60}
                                    className={addressIconStyles}
                                    onClick={onClickIcon}
                                    onMouseEnter={onHoverIcon}
                                />
                                <CSSTransition
                                    nodeRef={addressRef}
                                    in={contactFilter.address}
                                    timeout={600}
                                    mountOnEnter
                                    unmountOnExit
                                    classNames={{
                                        enter: "",
                                        enterActive:
                                            styles["contact-element__open"],
                                        exit: "",
                                        exitActive:
                                            styles["contact-element__close"],
                                    }}
                                >
                                    <ul
                                        className={styles["contact-address"]}
                                        ref={addressRef}
                                    >
                                        <a
                                            href="https://www.google.de/maps/place/MZT+GmbH/@51.4517071,7.3797334,17z/data=!3m2!4b1!5s0x47b9189d630a375f:0x4fe277dbd03baf57!4m5!3m4!1s0x47b918a7ee6a2631:0x3426ecb7faa205f1!8m2!3d51.4517071!4d7.3819221"
                                            className={
                                                styles[
                                                    "contact-address__element"
                                                ]
                                            }
                                        >
                                            <li
                                                className={
                                                    styles[
                                                        "contact-address__element"
                                                    ]
                                                }
                                            >
                                                MZT GmbH
                                            </li>
                                            <li
                                                className={
                                                    styles[
                                                        "contact-address__element"
                                                    ]
                                                }
                                            >
                                                Stockumer Straße 28
                                            </li>
                                            <li
                                                className={
                                                    styles[
                                                        "contact-address__element"
                                                    ]
                                                }
                                            >
                                                Gebäude B14
                                            </li>
                                            <li
                                                className={
                                                    styles[
                                                        "contact-address__element"
                                                    ]
                                                }
                                            >
                                                58453 Witten
                                            </li>
                                        </a>
                                    </ul>
                                </CSSTransition>
                            </div>

                            <div className={styles["contact-block"]}>
                                <BsFillTelephoneFill
                                    id="phone"
                                    size={60}
                                    className={phoneIconStyles}
                                    onClick={onClickIcon}
                                    onMouseEnter={onHoverIcon}
                                />
                                <CSSTransition
                                    nodeRef={phoneRef}
                                    in={contactFilter.phone}
                                    timeout={600}
                                    mountOnEnter
                                    unmountOnExit
                                    classNames={{
                                        enter: "",
                                        enterActive:
                                            styles["contact-element__open"],
                                        exit: "",
                                        exitActive:
                                            styles["contact-element__close"],
                                    }}
                                >
                                    <a
                                        href="tel:+492302912616"
                                        ref={phoneRef}
                                        className={styles["contact-element"]}
                                    >
                                        +49 2302 912616
                                    </a>
                                </CSSTransition>
                            </div>

                            <div className={styles["contact-block"]}>
                                <GrMail
                                    id="mail"
                                    size={60}
                                    className={mailIconStyles}
                                    onClick={onClickIcon}
                                    onMouseEnter={onHoverIcon}
                                />
                                <CSSTransition
                                    nodeRef={mailRef}
                                    in={contactFilter.mail}
                                    timeout={600}
                                    mountOnEnter
                                    unmountOnExit
                                    classNames={{
                                        enter: "",
                                        enterActive:
                                            styles["contact-element__open"],
                                        exit: "",
                                        exitActive:
                                            styles["contact-element__close"],
                                    }}
                                >
                                    <a
                                        ref={mailRef}
                                        className={styles["contact-element"]}
                                        href="mailto:info@mzt-gmbh.de"
                                    >
                                        info@mzt-gmbh.de
                                    </a>
                                </CSSTransition>
                            </div>

                            <div className={styles["contact-block"]}>
                                <FaLinkedin
                                    id="linkedin"
                                    size={60}
                                    className={linkedinIconStyles}
                                    onClick={onClickIcon}
                                    onMouseEnter={onHoverIcon}
                                />
                                <CSSTransition
                                    nodeRef={linkedinRef}
                                    in={contactFilter.linkedin}
                                    timeout={600}
                                    mountOnEnter
                                    unmountOnExit
                                    classNames={{
                                        enter: "",
                                        enterActive:
                                            styles["contact-element__open"],
                                        exit: "",
                                        exitActive:
                                            styles["contact-element__close"],
                                    }}
                                >
                                    <a
                                        href="https://de.linkedin.com/in/michael-marciniak-966632215"
                                        ref={linkedinRef}
                                        className={styles["contact-element"]}
                                    >
                                        Michael Marciniak
                                    </a>
                                </CSSTransition>
                            </div>

                            <div className={styles["contact-block"]}>
                                <FaXingSquare
                                    id="xing"
                                    size={60}
                                    className={xingIconStyles}
                                    onClick={onClickIcon}
                                    onMouseEnter={onHoverIcon}
                                />
                                <CSSTransition
                                    nodeRef={xingRef}
                                    in={contactFilter.xing}
                                    timeout={600}
                                    mountOnEnter
                                    unmountOnExit
                                    classNames={{
                                        enter: "",
                                        enterActive:
                                            styles["contact-element__open"],
                                        exit: "",
                                        exitActive:
                                            styles["contact-element__close"],
                                    }}
                                >
                                    <a
                                        href="https://www.xing.com/profile/Michael_Marciniak2"
                                        ref={xingRef}
                                        className={styles["contact-element"]}
                                    >
                                        Michael Marciniak
                                    </a>
                                </CSSTransition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Foot
                headline="Mehr Inspiration?"
                path="/services"
                btnText="Über uns"
            >
                Sie zögern noch, ob wir der richtige Ansprechpartner sind?{" "}
                <br /> <br /> Erfahren Sie mehr über unsere Dienstleistungen!
            </Foot>
        </React.Fragment>
    );
};

export default Contact;
