import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import styles from './MainFooter.module.css';

const MainFooter = (props) => {
    const [navItemLegalHovered, setNavItemLegalHovered] = useState(false);
    const [navItemPrivacyHovered, setNavItemPrivacyHovered] = useState(false);


    const onHoverLegal = () => {
        if(!navItemLegalHovered) setNavItemLegalHovered(true);
    }
    const onHoverPrivacy = () => {
        if(!navItemPrivacyHovered) setNavItemPrivacyHovered(true);
    }

    const navItemLegalStyles = navItemLegalHovered ? styles["footer-nav__item--hovered"] : styles["footer-nav__item"];
    const navItemPrivacyStyles = navItemPrivacyHovered ? styles["footer-nav__item--hovered"] : styles["footer-nav__item"];

    return (
        <footer className={styles['main-footer']}>
            <Link to="/" className={styles["main-footer_brand"]}>
                <img src={require(`../../../../img/logo.webp`)} alt="logo" />
            </Link>
            <nav className={styles['footer-nav']}>
                <ul className={styles['footer-nav__items']}>
                    <li className={navItemLegalStyles} onMouseEnter={onHoverLegal}>
                        <Link to='/legalnotice'>Impressum</Link>
                    </li>
                    <li className={navItemPrivacyStyles}  onMouseEnter={onHoverPrivacy}>
                        <Link to='/privacynotice'>Datenschutz</Link>
                    </li>
                </ul> 
            </nav>    
        </footer>    
    );
};

export default MainFooter;