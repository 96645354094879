import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import styles from "./PrivacyNotice.module.css";

import Head from "../../UI/Head/Head";
import Card from "../../UI/Card/Card";
import Foot from "../../UI/Foot/Foot";

const Privacy = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>Datenschutz</title>
                <meta name="description" content="Datenschutzerklärung - MZT GmbH" />
                <meta name="revisit-after" content="1 days" />
                <link rel="canonical" href="https://www.mzt-gmbh.de/privacynotice" />
            </Helmet>
            <Head>Datenschutzerklärung</Head>
            <div className={styles["background"]}>
                <div className={styles["privacyNotice__block"]}>
                    <h2 className={styles["headline"]}>
                        1. Datenschutz auf einen Blick
                    </h2>
                    <h3 className={styles["subheadline"]}>
                        Allgemeine Hinweise
                    </h3>
                    <p className={styles["text"]}>
                        Die folgenden Hinweise geben einen einfachen Überblick
                        darüber, was mit Ihren personenbezogenen Daten passiert,
                        wenn Sie unsere Website besuchen. Personenbezogene Daten
                        sind alle Daten, mit denen Sie persönlich identifiziert
                        werden können. Ausführliche Informationen zum Thema
                        Datenschutz entnehmen Sie unserer unter diesem Text
                        aufgeführten Datenschutzerklärung.
                    </p>
                    <h3 className={styles["subheadline"]}>
                        Datenerfassung auf unserer Webseite
                    </h3>
                    <Card className={styles["card"]}>
                        <h4>
                            Wer ist verantwortlich für die Datenerfassung auf
                            dieser Webseite?
                        </h4>
                        <p>
                            Die Datenverarbeitung auf dieser Website erfolgt
                            durch den Websitebetreiber. Dessen Kontaktdaten
                            können Sie dem Impressum dieser Website entnehmen.
                        </p>
                    </Card>
                    <Card className={styles["card"]}>
                        <h4>Wie erfassen wir Ihre Daten?</h4>
                        <p>
                            Ihre Daten werden zum einen dadurch erhoben, dass
                            Sie uns diese mitteilen. Hierbei kann es sich z.B.
                            um Daten handeln, die Sie in ein Kontaktformular
                            eingeben.
                        </p>

                        <p>
                            Andere Daten werden automatisch beim Besuch der
                            Website durch unsere IT-Systeme erfasst. Das sind
                            vor allem technische Daten (z.B. Internetbrowser,
                            Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
                            Erfassung dieser Daten erfolgt automatisch, sobald
                            Sie unsere Website betreten.
                        </p>
                    </Card>

                    <Card className={styles["card"]}>
                        <h4>Wofür nutzen wir Ihre Daten?</h4>
                        <p>
                            Ein Teil der Daten wird erhoben, um eine fehlerfreie
                            Bereitstellung der Website zu gewährleisten. Andere
                            Daten können zur Analyse Ihres Nutzerverhaltens
                            verwendet werden.
                        </p>
                    </Card>
                    <Card className={styles["card"]}>
                        <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
                        <p>
                            Sie haben jederzeit das Recht unentgeltlich Auskunft
                            über Herkunft, Empfänger und Zweck Ihrer
                            gespeicherten personenbezogenen Daten zu erhalten.
                            Sie haben außerdem ein Recht, die Berichtigung,
                            Sperrung oder Löschung dieser Daten zu verlangen.
                            Hierzu sowie zu weiteren Fragen zum Thema
                            Datenschutz können Sie sich jederzeit unter der im
                            Impressum angegebenen Adresse an uns wenden. Des
                            Weiteren steht Ihnen ein Beschwerderecht bei der
                            zuständigen Aufsichtsbehörde zu.
                        </p>
                    </Card>

                    {/* <h3 className={styles["subheadline"]}>
                        Analyse-Tools und Tools von Drittanbietern
                    </h3>
                    <p className={styles["text"]}>
                        Beim Besuch unserer Website kann Ihr Surf-Verhalten
                        statistisch ausgewertet werden. Das geschieht vor allem
                        mit Cookies und mit sogenannten Analyseprogrammen. Die
                        Analyse Ihres Surf-Verhaltens erfolgt in der Regel
                        anonym; das Surf-Verhalten kann nicht zu Ihnen
                        zurückverfolgt werden. Sie können dieser Analyse
                        widersprechen oder sie durch die Nichtbenutzung
                        bestimmter Tools verhindern. Detaillierte Informationen
                        dazu finden Sie in der folgenden Datenschutzerklärung
                    </p> */}
                    {/* <p className={styles["text"]}>
                        Sie können dieser Analyse widersprechen. Über die
                        Widerspruchsmöglichkeiten werden wir Sie in dieser
                        Datenschutzerklärung informieren.
                    </p> */}
                    <h2 className={styles["headline"]}>
                        2. Allgemeine Hinweise und Pflichtinformationen
                    </h2>
                    <h3 className={styles["subheadline"]}>Datenschutz</h3>
                    <p className={styles["text"]}>
                        Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                        persönlichen Daten sehr ernst. Wir behandeln Ihre
                        personenbezogenen Daten vertraulich und entsprechend der
                        gesetzlichen Datenschutzvorschriften sowie dieser
                        Datenschutzerklärung.
                    </p>
                    <br />
                    <p className={styles["text"]}>
                        Wenn Sie diese Website benutzen, werden verschiedene
                        personenbezogene Daten erhoben. Personenbezogene Daten
                        sind Daten, mit denen Sie persönlich identifiziert
                        werden können. Die vorliegende Datenschutzerklärung
                        erläutert, welche Daten wir erheben und wofür wir sie
                        nutzen. Sie erläutert auch, wie und zu welchem Zweck das
                        geschieht.
                    </p>
                    <br />
                    <p className={styles["text"]}>
                        Wir weisen darauf hin, dass die Datenübertragung im
                        Internet (z. B. bei der Kommunikation per E-Mail)
                        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz
                        der Daten vor dem Zugriff durch Dritte ist nicht
                        möglich.
                    </p>
                    <h3 className={styles["subheadline"]}>
                        Hinweis zur verantwortlichen Stelle
                    </h3>
                    <p className={styles["text"]}>
                        Die verantwortliche Stelle für die Datenverarbeitung auf
                        dieser Website ist:
                    </p>
                    <br />
                    <p className={styles["text"]}>
                        MZT GmbH
                        <br />
                        Dipl.-Ing. Michael Marciniak (Geschäftsführer)
                        <br />
                        Stockumerstraße 28 58453 Witten
                    </p>
                    <br />
                    <p className={styles["text"]}>
                        Telefon: 02302-912616
                        <br />
                        E-Mail: info@mzt-gmbh.de
                    </p>
                    <br />
                    <p className={styles["text"]}>
                        Verantwortliche Stelle ist die natürliche oder
                        juristische Person, die allein oder gemeinsam mit
                        anderen über die Zwecke und Mittel der Verarbeitung von
                        personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o.
                        Ä.) entscheidet.
                    </p>

                    <h3 className={styles["subheadline"]}>
                        Widerruf ihrer Einwilligung zur Datenverarbeitung
                    </h3>
                    <p className={styles["text"]}>
                        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
                        ausdrücklichen Einwilligung möglich. Sie können eine
                        bereits erteilte Einwilligung jederzeit widerrufen. Dazu
                        reicht eine formlose Mitteilung per E-Mail an uns. Die
                        Rechtmäßigkeit der bis zum Widerruf erfolgten
                        Datenverarbeitung bleibt vom Widerruf unberührt.
                    </p>
                    <h3 className={styles["subheadline"]}>
                        Widerspruch gegen die Datenerhebung in besonderen Fällen
                    </h3>
                    <p className={styles["text"]}>
                        Wenn die Datenverarbeitung auf Grundlage von ART. 6 ABS.
                        1 LIT. E oder F DSGVO erfolgt, haben Sie jederzeit das
                        Recht, aus Gründen, die sich aus Ihrer besonderen
                        Situation ergeben, gegen die Verarbeitung Ihrer
                        personenbesogenen Daten Widerspruch einzulegen. Dies
                        gilt auch für ein auf diese Bestimmungen gestütztes
                        Profiling. Die jeweilige Rechtsgrundlage, auf denen eine
                        Verarbeitung beruht, entnehmen Sie dieser
                        Datenschutzerklärung. Wenn Sie Widerspruch einlegen,
                        werden wir Ihre betroffenen personenbezogenen Daten
                        nicht mehr verarbeiten, es sei denn, wir können
                        zwingende schutzwürdige Gründe für die Verarbeitung
                        nachweisen, die Ihre Interessen, Rechte und Freiheiten
                        überwiegen oder die Verarbeitung dient der
                        Geltendmachung, Ausübung oder Verteidigung von
                        Rechtsansprüchen (Widerspruch nach ART. 21 ABS. 1 DSGVO)
                    </p>
                    {/* <br /> */}
                    {/* <p className={styles["text"]}>
                        Werden Ihre personenbezogenen Daten verarbeitet, um
                        Direktwerbung zu betreiben, so haben Sie das Recht,
                        jederzeit Widerspruch gegen die Verarbeitung Sie
                        betreffender personenbezogener Daten zum Zwecke
                        derartiger Werbung einzulegen. Dies gilt auch für das
                        Profiling, soweit es mit solcher Direktwerbung in
                        Verbindung steht. Wenn Sie Widersprechen, werden Ihre
                        personenbezogenen Daten anschliessend nicht mehr zum
                        Zwecke der Direktwerbung verwendet (Widerspruch nach
                        ART. 21 ABS. 2 DSGVO)
                    </p> */}
                    <h3 className={styles["subheadline"]}>
                        Beschwerderecht bei der zuständigen Aufsichtsbehörde
                    </h3>
                    <p className={styles["text"]}>
                        Im Falle von Verstößen gegen die DSGVO steht den
                        Betroffenen ein Beschwerderecht bei einer
                        Aufsichtsbehörde, insbesondere in dem Mitgliedstaat
                        ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
                        oder des Orts des mutmaßlichen Verstoßes zu. Das
                        Beschwerderecht besteht unbeschadet anderweitiger
                        verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
                    </p>
                    <h3 className={styles["subheadline"]}>
                        Recht auf Datenübertragbarkeit
                    </h3>
                    <p className={styles["text"]}>
                        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                        Einwilligung oder in Erfüllung eines Vertrags
                        automatisiert verarbeiten, an sich oder an einen Dritten
                        in einem gängigen, maschinenlesbaren Format aushändigen
                        zu lassen. Sofern Sie die direkte Übertragung der Daten
                        an einen anderen Verantwortlichen verlangen, erfolgt
                        dies nur, soweit es technisch machbar ist.
                    </p>
                    <h3 className={styles["subheadline"]}>
                        SSL- bzw. TLS-Verschlüsselung
                    </h3>
                    <p className={styles["text"]}>
                        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz
                        der Übertragung vertraulicher Inhalte, wie zum Beispiel
                        Bestellungen oder Anfragen, die Sie an uns als
                        Seitenbetreiber senden, eine SSL- bzw.
                        TLS-Verschlüsselung. Eine verschlüsselte Verbindung
                        erkennen Sie daran, dass die Adresszeile des Browsers
                        von „http://“ auf „https://“ wechselt und an dem
                        Schloss-Symbol in Ihrer Browserzeile.
                    </p>
                    <br />
                    <p className={styles["text"]}>
                        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist,
                        können die Daten, die Sie an uns übermitteln, nicht von
                        Dritten mitgelesen werden.
                    </p>
                    <h3 className={styles["subheadline"]}>
                        Auskunft, Löschung, Berichtigung
                    </h3>
                    <p className={styles["text"]}>
                        Sie haben im Rahmen der geltenden gesetzlichen
                        Bestimmungen jederzeit das Recht auf unentgeltliche
                        Auskunft über Ihre gespeicherten personenbezogenen
                        Daten, deren Herkunft und Empfänger und den Zweck der
                        Datenverarbeitung und ggf. ein Recht auf Berichtigung
                        oder Löschung dieser Daten. Hierzu sowie zu weiteren
                        Fragen zum Thema personenbezogene Daten können Sie sich
                        jederzeit unter der im Impressum angegebenen Adresse an
                        uns wenden.
                    </p>
                    <h3 className={styles["subheadline"]}>
                        Recht auf Einschränkung der Verarbeitung
                    </h3>
                    <p className={styles["text"]}>
                        Sie haben das Recht, die Einschränkung der Verarbeitung
                        Ihrer personenbezogenen Daten zu verlangen. Hierzu
                        können Sie sich jederzeit unter der im Impressum
                        angegebenen Adresse an uns wenden. Das Recht auf
                        Einschränkung der Verarbeitung besteht in folgenden
                        Fällen:
                    </p>
                    <ul className={styles["list"]}>
                        <li>
                            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                            personenbezogenen Daten bestreiten, benötigen wir in
                            der Regel Zeit, um dies zu überprüfen. Für die Dauer
                            der Prüfung haben Sie das Recht, die Einschränkung
                            der Verarbeitung Ihrer personenbezogenen Daten zu
                            verlangen.
                        </li>
                        <li>
                            Wenn die Verarbeitung Ihrer personenbezogenen Daten
                            unrechtmäßig geschah/geschieht, können Sie statt der
                            Löschung die Einschränkung der Datenverarbeitung
                            verlangen.
                        </li>
                        <li>
                            Wenn wir Ihre personenbezogenen Daten nicht mehr
                            benötigen, Sie sie jedoch zur Ausübung, Verteidigung
                            oder Geltendmachung von Rechtsansprüchen benötigen,
                            haben Sie das Recht, statt der Löschung die
                            Einschränkung der Verarbeitung Ihrer
                            personenbezogenen Daten zu verlangen.
                        </li>
                        <li>
                            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO
                            eingelegt haben, muss eine Abwägung zwischen Ihren
                            und unseren Interessen vorgenommen werden. Solange
                            noch nicht feststeht, wessen Interessen überwiegen,
                            haben Sie das Recht, die Einschränkung der
                            Verarbeitung Ihrer personenbezogenen Daten zu
                            verlangen.
                        </li>
                    </ul>
                    <p className={styles["text"]}>
                        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                        eingeschränkt haben, dürfen diese Daten – von ihrer
                        Speicherung abgesehen – nur mit Ihrer Einwilligung oder
                        zur Geltendmachung, Ausübung oder Verteidigung von
                        Rechtsansprüchen oder zum Schutz der Rechte einer
                        anderen natürlichen oder juristischen Person oder aus
                        Gründen eines wichtigen öffentlichen Interesses der
                        Europäischen Union oder eines Mitgliedstaats verarbeitet
                        werden.
                    </p>
                    {/* <h3 className={styles["subheadline"]}>
                        Widerspruch gegen Werbemails
                    </h3>
                    <p className={styles["text"]}>
                        Der Nutzung von im Rahmen der Impressumspflicht
                        veröffentlichten Kontaktdaten zur Übersendung von nicht
                        ausdrücklich angeforderter Werbung und
                        Informationsmaterialien wird hiermit widersprochen. Die
                        Betreiber der Seiten behalten sich ausdrücklich
                        rechtliche Schritte im Falle der unverlangten Zusendung
                        von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                    </p> */}
                    <h2 className={styles["headline"]}>
                        3. Datenerfassung auf unserer Webseite
                    </h2>
                    {/* <h3 className={styles["subheadline"]}>Cookies</h3>
                    <p className={styles["text"]}>
                        Unsere Internetseiten verwenden so genannte „Cookies“.
                        Cookies sind kleine Textdateien und richten auf Ihrem
                        Endgerät keinen Schaden an. Sie werden entweder
                        vorübergehend für die Dauer einer Sitzung
                        (Session-Cookies) oder dauerhaft (permanente Cookies)
                        auf Ihrem Endgerät gespeichert. Session-Cookies werden
                        nach Ende Ihres Besuchs automatisch gelöscht. Permanente
                        Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie
                        diese selbst löschen oder eine automatische Lösung durch
                        Ihren Webbrowser erfolgt.
                    </p>
                    <br />
                    <p className={styles["text"]}>
                        Teilweise können auch Cookies von Drittunternehmen auf
                        Ihrem Endgerät gespeichert werden, wenn Sie unsere Seite
                        betreten (Third-Party-Cookies). Diese ermöglichen uns
                        oder Ihnen die Nutzung bestimmter Dienstleistungen des
                        Drittunternehmens (z.B. Cookies zur Abwicklung von
                        Zahlungsdienstleistungen).
                    </p>
                    <br />
                    <p className={styles["text"]}>
                        Cookies haben verschiedene Funktionen. Zahlreiche
                        Cookies sind technisch notwendig, da bestimmte
                        Webseitenfunktionen ohne diese nicht funktionieren
                        würden (z.B. die Warenkorbfunktion oder die Anzeige von
                        Videos). Andere Cookies dienen dazu das Nutzerverhalten
                        auszuwerten oder Werbung anzuzeigen.
                    </p>
                    <br />
                    <p className={styles["text"]}>
                        Cookies, die zur Durchführung des elektronischen
                        Kommunikationsvorgangs oder zur Bereitstellung
                        bestimmter, von Ihnen erwünschter Funktionen (z. B.
                        Warenkorbfunktion) erforderlich sind, werden auf
                        Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert.
                        Der Websitebetreiber hat ein berechtigtes Interesse an
                        der Speicherung von Cookies zur technisch fehlerfreien
                        und optimierten Bereitstellung seiner Dienste. Sofern
                        eine entsprechende Einwilligung abgefragt wurde (z. B.
                        eine Einwilligung zur Speicherung von Cookies), erfolgt
                        die Verarbeitung ausschließlich auf Grundlage von Art. 6
                        Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
                        widerrufbar.
                    </p>
                    <br />
                    <p className={styles["text"]}>
                        Sie können Ihren Browser so einstellen, dass Sie über
                        das Setzen von Cookies informiert werden und Cookies nur
                        im Einzelfall erlauben, die Annahme von Cookies für
                        bestimmte Fälle oder generell ausschließen sowie das
                        automatische Löschen der Cookies beim Schließen des
                        Browsers aktivieren. Bei der Deaktivierung von Cookies
                        kann die Funktionalität dieser Website eingeschränkt
                        sein.
                    </p>
                    <br />
                    <p className={styles["text"]}>
                        Soweit Cookies von Drittunternehmen oder zu
                        Analysezwecken eingesetzt werden, werden wir Sie
                        hierüber im Rahmen dieser Datenschutzerklärung gesondert
                        informieren und ggf. eine Einwilligung abfragen.
                    </p> */}
                    <h3 className={styles["subheadline"]}>
                        Server Log-Dateien
                    </h3>
                    <p className={styles["text"]}>
                        Der Provider der Seiten erhebt und speichert automatisch
                        Informationen in so genannten Server-Log-Dateien, die
                        Ihr Browser automatisch an uns übermittelt. Dies sind:
                    </p>
                    <ul className={styles["list"]}>
                        <li>Browsertyp und Browserversion</li>
                        <li>verwendetes Betriebssystem</li>
                        <li>Referrer URL</li>
                        <li>Hostname des zugreifenden Rechners</li>
                        <li>Uhrzeit der Serveranfrage</li>
                        <li>IP-Adresse</li>
                    </ul>
                    <p className={styles["text"]}>
                        Eine Zusammenführung dieser Daten mit anderen
                        Datenquellen wird nicht vorgenommen.
                    </p>
                    <p className={styles["text"]}>
                        Die Erfassung dieser Daten erfolgt auf Grundlage von
                        Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                        berechtigtes Interesse an der technisch fehlerfreien
                        Darstellung und der Optimierung seiner Website – hierzu
                        müssen die Server-Log-Files erfasst werden.
                    </p>
                    <h3 className={styles["subheadline"]}>
                        Kontakt- und Bewerbungsformular
                    </h3>
                    <p className={styles["text"]}>
                        Wenn Sie uns per Kontakt- oder Bewerbungsformular
                        Anfragen zukommen lassen, werden Ihre Angaben aus dem
                        Anfrageformular inklusive der von Ihnen dort angegebenen
                        Kontaktdaten zwecks Bearbeitung der Anfrage und für den
                        Fall von Anschlussfragen bei uns gespeichert. Diese
                        Daten geben wir nicht ohne Ihre Einwilligung weiter.
                    </p>
                    <p className={styles["text"]}>
                        Die Verarbeitung dieser Daten erfolgt auf Grundlage von
                        Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der
                        Erfüllung eines Vertrags zusammenhängt oder zur
                        Durchführung vorvertraglicher Maßnahmen erforderlich
                        ist. In allen übrigen Fällen beruht die Verarbeitung auf
                        unserem berechtigten Interesse an der effektiven
                        Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs.
                        1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs.
                        1 lit. a DSGVO) sofern diese abgefragt wurde.
                    </p>
                    <p className={styles["text"]}>
                        Die von Ihnen im Kontakt- oder Bewerbungsformular
                        eingegebenen Daten verbleiben bei uns, bis Sie uns zur
                        Löschung auffordern, Ihre Einwilligung zur Speicherung
                        widerrufen oder der Zweck für die Datenspeicherung
                        entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer
                        Anfrage). Zwingende gesetzliche Bestimmungen –
                        insbesondere Aufbewahrungsfristen – bleiben unberührt.
                    </p>
                    <h3 className={styles["subheadline"]}>
                        Anfrage per E-Mail, Telefon oder Telefax
                    </h3>
                    <p className={styles["text"]}>
                        Wenn Sie uns per E-Mail, Telefon oder Telefax
                        kontaktieren, wird Ihre Anfrage inklusive aller daraus
                        hervorgehenden personenbezogenen Daten (Name, Anfrage)
                        zum Zwecke der Bearbeitung Ihres Anliegens bei uns
                        gespeichert und verarbeitet. Diese Daten geben wir nicht
                        ohne Ihre Einwilligung weiter.
                    </p>
                    <p className={styles["text"]}>
                        Die Verarbeitung dieser Daten erfolgt auf Grundlage von
                        Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der
                        Erfüllung eines Vertrags zusammenhängt oder zur
                        Durchführung vorvertraglicher Maßnahmen erforderlich
                        ist. In allen übrigen Fällen beruht die Verarbeitung auf
                        Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und/oder
                        auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit.
                        f DSGVO), da wir ein berechtigtes Interesse an der
                        effektiven Bearbeitung der an uns gerichteten Anfragen
                        haben.
                    </p>
                    <p className={styles["text"]}>
                        Die von Ihnen an uns per Kontaktanfragen übersandten
                        Daten verbleiben bei uns, bis Sie uns zur Löschung
                        auffordern, Ihre Einwilligung zur Speicherung widerrufen
                        oder der Zweck für die Datenspeicherung entfällt (z. B.
                        nach abgeschlossener Bearbeitung Ihres Anliegens).
                        Zwingende gesetzliche Bestimmungen – insbesondere
                        gesetzliche Aufbewahrungsfristen – bleiben unberührt.
                    </p>
                </div>
            </div>
            <Foot
                headline="Was wir machen?"
                path="/services"
                btnText="Über uns"
            >
                Erfahren Sie wer wir sind und wie wir Sie unterstützen können!
            </Foot>
        </React.Fragment>
    );
};

export default Privacy;
