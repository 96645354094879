import axios from "axios";
import { API_URL } from "./apiURL";

// async function postContactForm(name, mail, msg) {
//     return fetch("http://webseite-flask:5000/contact_form", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         name: name,
//         mail: mail,
//         msg: msg 
//       }),
//     }).then((data) => data.json());
//   }
  
  async function postContactForm(name, mail, msg) {
    const response = await axios.post(`${API_URL}/contact_form`, {
      name: name,
      mail: mail,
      msg: msg
    })

    return response.data;
}


  export default postContactForm;
  