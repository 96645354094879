import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import styles from "./PersonForm.module.css";

import Person from "./Person";
import ArrowSwitch from "../../UI/ArrowSwitch/ArrowSwitch";

const PersonForm = (props) => {
    const [activePerson, setActivePerson] = useState(0);

    const changePersonNext = () => {
        var newActive = activePerson;

        if (++newActive >= props.persons.length) newActive = 0;

        setActivePerson(newActive);
    };

    const changePersonPrev = () => {
        var newActive = activePerson;

        if (--newActive < 0) newActive = props.persons.length - 1;

        setActivePerson(newActive);
    };

    return (
        <React.Fragment>
            {props.persons[activePerson] && props.persons.length > 1 && (
                <div className={styles.container}>
                    {props.headline && <h3>{props.headline}</h3>}
                    <Swiper
                        className={styles["persons"]}
                        spaceBetween={50}
                        loop={true}
                        onSlideNextTransitionEnd={changePersonNext}
                        onSlidePrevTransitionEnd={changePersonPrev}
                        onAfterInit={() => setActivePerson(0)}
                    >
                        {props.persons.map((person, index) => (
                            <SwiperSlide key={index}>
                                <Person name={person.name} img={person.img}>
                                    {person.description}
                                </Person>
                            </SwiperSlide>
                        ))}
                        {props.persons.length > 1 && (
                            <ArrowSwitch
                                projectNumber={props.persons.length}
                                activeProject={activePerson}
                            />
                        )}
                    </Swiper>
                </div>
            )}
            {props.persons[activePerson] && props.persons.length === 1 && (
                <div className={styles.container}>
                    {props.headline && <h3>{props.headline}</h3>}
                    <Swiper
                        className={styles["persons"]}
                        spaceBetween={50}
                        loop={false}
                        onSlideNextTransitionEnd={changePersonNext}
                        onSlidePrevTransitionEnd={changePersonPrev}
                        onAfterInit={() => setActivePerson(0)}
                    >
                        {props.persons.map((person, index) => (
                            <SwiperSlide key={index}>
                                <Person name={person.name} img={person.img}>
                                    {person.description}
                                </Person>
                            </SwiperSlide>
                        ))}
                        {props.persons.length > 1 && (
                            <ArrowSwitch
                                projectNumber={props.persons.length}
                                activeProject={activePerson}
                            />
                        )}
                    </Swiper>
                </div>
            )}
            {!props.persons[activePerson] && (
                <React.Fragment>
                    <div className={styles.backdrop} />
                    <div className={styles["lds-ring"]}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PersonForm;
